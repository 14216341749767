import { Skeleton } from "@mui/material";

export const DashboardSkeleton = () => {
  return (
    <div className=" h-full flex-1 flex-col p-0">
      <div className="mb-3 flex w-full flex-1 items-end justify-end">
        <Skeleton
          variant="rectangular"
          width={134}
          height={37}
          className="mb-2"
        />
      </div>
      <div className="flex flex-col">
        {Array.from({ length: 5 }).map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            width="100%"
            className="mb-3"
            height={82}
          />
        ))}
      </div>
    </div>
  );
};
