import { useAuth0 } from "@auth0/auth0-react";
import {
  GroupsOutlined,
  Person2Outlined,
  PowerSettingsNewOutlined,
  ReceiptLongOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { Divider, Menu, Typography } from "@mui/material";

import { useSnackbar } from "@components";
import { useCreateBillingSessionMutation } from "@hooks";
import { Plan, User } from "@models";
import { getSubscription } from "@utils";
import { ROUTES } from "src/config";
import { UserCard } from "./UserCard";

type Props = {
  anchorUserMenu: HTMLElement | null;
  isOpen: boolean;
  close: () => void;
  user: User;
};

const plansBtnDictionary = {
  [Plan.FREE]: "Upgrade to Premium",
  [Plan.PREMIUM]: "Upgrade to Premium+",
  [Plan.PREMIUM_PLUS]: "Plans",
};

export const UserMenu = ({ anchorUserMenu, isOpen, close, user }: Props) => {
  const { logout } = useAuth0();
  const { showSnackbar } = useSnackbar();
  const { mutateAsync: createBillingSession } =
    useCreateBillingSessionMutation();

  if (!user) return null;

  const { product: activeProduct } = getSubscription(user);

  const handleGoToBilling = async () => {
    const billingUrl = await createBillingSession();
    if (billingUrl) {
      window.open(billingUrl, "_blank");
    } else {
      showSnackbar(
        "Failed to create billing session. Please try again or contact support."
      );
    }
  };

  return (
    <Menu
      id="user-menu"
      anchorEl={anchorUserMenu}
      open={isOpen}
      onClose={close}
      onClick={close}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      disablePortal
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            maxHeight: 300,
          },
        },
      }}
    >
      <Typography variant="body1" className="px-4 font-bold flex gap-3">
        <Person2Outlined />
        {user.firstName} {user.lastName}
      </Typography>
      <Typography variant="body2" className="px-4 flex gap-3.5 items-center">
        <GroupsOutlined />
        {user.organization.name}
      </Typography>
      <Divider className="my-2" />
      {activeProduct === Plan.FREE || activeProduct === Plan.PREMIUM ? (
        <UserCard
          key={user.organizationId}
          to={ROUTES.SUBSCRIPTION}
          text={plansBtnDictionary[activeProduct]}
          icon={<WorkspacePremiumOutlined color="primary" />}
        />
      ) : (
        <UserCard
          key={user.organizationId}
          to={ROUTES.SUBSCRIPTION}
          text={"Plans"}
          icon={<WorkspacePremiumOutlined color="primary" />}
        />
      )}
      {(activeProduct === Plan.PREMIUM ||
        activeProduct === Plan.PREMIUM_PLUS) && (
        <UserCard
          onClick={handleGoToBilling}
          text={"Billing"}
          icon={<ReceiptLongOutlined />}
        />
      )}
      <UserCard
        onClick={() => logout({ returnTo: window.location.origin })}
        key="logout"
        text="Logout"
        icon={<PowerSettingsNewOutlined />}
      />
    </Menu>
  );
};
