import { TextField, Typography } from "@mui/material";

export const RevisionSummary = ({
  disabled = false,
  revisionSummary,
  setRevisionSummary,
}: {
  revisionSummary: string;
  setRevisionSummary: (revisionSummary: string) => void;
  disabled?: boolean;
}) => (
  <div className="flex flex-col">
    <Typography variant="subtitle1" fontWeight="bold">
      Revision Summary
    </Typography>
    {!disabled && (
      <Typography variant="subtitle2">
        Please define the changes in this revision. If it is the initial
        release, state so. Revision histories will be available during audits so
        provide sufficient detail.
      </Typography>
    )}
    <TextField
      hiddenLabel
      className="pt-2"
      disabled={disabled}
      fullWidth
      minRows={3}
      multiline
      value={revisionSummary}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setRevisionSummary(event.target.value);
      }}
    />
  </div>
);

export default RevisionSummary;
