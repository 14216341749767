import { FolderOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { ASSISTANT_CONFIG } from "../../config";
import { FunctionalGroupNames } from "../../config/assistant";
import { TEMPLATE_TYPE } from "../../stores/models/template-document";
import { DocProgress, DocProgressMap, FunctionalGroup } from "../../types";
import TreeViewSkeleton from "./TreeViewSkeleton";
import DocExplorerLeaf from "./components/DocExplorerLeaf";

const getFunctionalGroupsToDocsMap = (docProgressMap: DocProgressMap) => {
  const functionalGroupToDocMap: Partial<
    Record<FunctionalGroup, DocProgress[]>
  > = {};
  Object.values(docProgressMap).forEach((docProgress) => {
    if (functionalGroupToDocMap[docProgress.functionalGroup] === undefined) {
      functionalGroupToDocMap[docProgress.functionalGroup] = [docProgress];
    } else {
      functionalGroupToDocMap[docProgress.functionalGroup]?.push(docProgress);
    }
  });
  return functionalGroupToDocMap;
};

const generateTree = (deviceId: string, docProgressMap: DocProgressMap) => {
  // Group documents by functional group
  const functionalGroupsToDocsMap =
    getFunctionalGroupsToDocsMap(docProgressMap);

  // Generate top layer functional groups first, put document leaves inside them
  return Object.entries(functionalGroupsToDocsMap)
    .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
    .map(([functionalGroup, docs]) => {
      return (
        <TreeItem
          key={functionalGroup}
          itemId={functionalGroup}
          label={FunctionalGroupNames[functionalGroup as FunctionalGroup]}
        >
          {docs
            // Filter out hidden documents
            .filter(
              (docProgress) =>
                docProgressMap[docProgress.docTemplate]?.hidden !== true
            )
            .sort((a, b) => a.docTemplate.localeCompare(b.docTemplate))
            .map((docProgress) => (
              <DocExplorerLeaf
                template={ASSISTANT_CONFIG[docProgress.docTemplate]}
                deviceId={deviceId}
                docProgress={docProgress}
                key={`${docProgress.docTemplate}-link`}
              />
            ))}
        </TreeItem>
      );
    });
};

export const DocExplorer = ({
  deviceId,
  templateId,
  className = "",
  docProgressMap,
  isLoading = false,
}: {
  deviceId: string;
  templateId?: TEMPLATE_TYPE;
  className?: string;
  docProgressMap: DocProgressMap;
  isLoading?: boolean;
}) => {
  const docProgressMapIsEmpty = Object.keys(docProgressMap).length === 0;

  return (
    <div
      className={`min-h-[30rem] min-w-[16rem] overflow-auto rounded border border-solid border-slate-300 ${className}`}
    >
      <div className="flex items-center gap-2 border-0 border-b border-solid border-slate-300 p-2">
        <FolderOutlined className="text-base" />
        <Typography variant="body1">Document Explorer</Typography>
      </div>
      <div className="py-2">
        {isLoading ? (
          <TreeViewSkeleton />
        ) : docProgressMapIsEmpty ? (
          <Typography variant="body2" className="text-center mt-2 opacity-50">
            You don't have any documents yet.
          </Typography>
        ) : (
          <SimpleTreeView
            aria-label="file system navigator"
            defaultExpandedItems={
              templateId && [ASSISTANT_CONFIG[templateId].functionalGroup]
            }
            selectedItems={templateId || ""}
          >
            {generateTree(deviceId, docProgressMap)}
          </SimpleTreeView>
        )}
      </div>
    </div>
  );
};
