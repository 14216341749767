import { Skeleton } from "@mui/material";

export const ApproversTabContentLoading = () => {
  return (
    <div className="flex flex-col gap-5 pt-4">
      <div className="flex flex-col gap-2">
        <Skeleton variant="rectangular" height={30} width={200} />
        <div className="flex gap-6 pt-3">
          <Skeleton variant="rectangular" height={50} width={200} />
          <Skeleton variant="rectangular" height={50} width={200} />
        </div>
        <Skeleton variant="rectangular" height={30} width={150} />
      </div>
      <div>
        <Skeleton variant="rectangular" height={30} width={200} />
        <div className="flex gap-6 pt-3">
          <Skeleton variant="rectangular" height={70} width={"100%"} />
        </div>
      </div>
      <Skeleton variant="rectangular" height={40} width={"100%"} />
    </div>
  );
};

export default ApproversTabContentLoading;
