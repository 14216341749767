import { Edit } from "@mui/icons-material";
import { IconButton, Skeleton, Typography } from "@mui/material";

const PageTitle = ({
  title,
  enableEdit = false,
  editOnClick,
  isLoading,
}: {
  title: string;
  enableEdit?: boolean;
  editOnClick?: () => void;
  isLoading?: boolean;
}) => {
  return (
    <div className="flex items-center gap-x-2">
      {isLoading ? (
        <Skeleton variant="rounded" height={40} width={200} />
      ) : (
        <Typography variant="h1">{title}</Typography>
      )}
      {enableEdit ? (
        <IconButton size="small" onClick={editOnClick}>
          <Edit className="text-gray-400" />
        </IconButton>
      ) : null}
    </div>
  );
};

export default PageTitle;
