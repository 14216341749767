import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Cards, RoadmapSkeleton, TaskModal } from "../components";
import PageTitle from "../components/PageTitle";
import {
  RoadmapCardId,
  RoadmapStageId,
  getDeviceQueryKey,
  getDevicesQueryKey,
} from "../config";
import { useGetDevice, useGetTasks, useGetUser } from "../hooks";
import { useStore } from "../stores";
import { Device } from "../stores/models";

export const Roadmap = () => {
  const { deviceStore } = useStore();
  const { deviceId = "" } = useParams();
  const queryClient = useQueryClient();
  if (!deviceId) throw new Error("No device ID provided");

  const [selectedCard, setSelectedCard] = useState<
    { card: RoadmapCardId; phase: RoadmapStageId } | undefined
  >();
  const [updatedDevice, setUpdatedDevice] = useState<Device | undefined>(
    undefined
  );

  const [editDevice, setEditDevice] = useState(false);

  useGetUser();

  const { data: device } = useGetDevice(deviceId);

  const { isLoading: tasksLoading, data: tasks } = useGetTasks(deviceId);

  const updateDevice = useMutation({
    mutationFn: (device: Device) => deviceStore.updateDevice(device),
    onMutate: (device) => {
      queryClient.setQueryData(getDeviceQueryKey(device.id), device);
    },
    onSuccess: (device) => {
      queryClient.invalidateQueries({ queryKey: getDeviceQueryKey(device.id) });
      queryClient.invalidateQueries({ queryKey: getDevicesQueryKey() });
    },
  });

  if (!device || tasksLoading || !tasks) {
    return <RoadmapSkeleton />;
  }

  const roadmap = deviceStore.mapRoadmap(
    tasks.tasks,
    device.roadmapQuestionnaire
  );

  const handleClickOpenCardDialog = (
    card: RoadmapCardId,
    phase: RoadmapStageId
  ) => {
    setSelectedCard({ card, phase });
  };

  const handleCloseCardDialog = () => {
    setSelectedCard(undefined);
  };

  const card = roadmap
    .find((step) => step.id === selectedCard?.phase)
    ?.cards.find((card) => card.id === selectedCard?.card);

  const first =
    "polygon(0 0,calc(100% - 6px) 0,100% 50%,calc(100% - 6px) 100%,0 100%)";
  const all =
    "polygon(calc(100% - 6px) 0,100% 50%,calc(100% - 6px) 100%,2px 100%,8px 50%,2px 0)";
  return (
    <div className="flex flex-col gap-y-3">
      <PageTitle
        title={device.name}
        enableEdit
        editOnClick={() => setEditDevice(true)}
      />

      <Typography variant="h2">Roadmap</Typography>

      <div className="flex flex-col">
        <div className="flex">
          {roadmap.map((step, index) => {
            const stageCompleted =
              step.cards.length > 0 &&
              step.cards.every(
                (card) => card.tasks.every((task) => task.completed) === true
              );

            const color1 = "rgb(21,128, 61)";
            const color2 = "rgb(203, 213, 225)";
            const percentage =
              step.cards.filter(
                (card) => card.tasks.every((task) => task.completed) === true
              ).length / step.cards.length || 0 * 100;

            const completedWidth = parseFloat(percentage.toFixed(2)) * 100;
            const uncompletedWidth = (1 - completedWidth) * 100;

            return (
              <div key={step.title} className="flex flex-1 flex-col">
                <div
                  className={`flex flex-1 items-center justify-center px-3 py-1 `}
                  style={{
                    background: `linear-gradient(to right,  ${color1}, ${color1} ${completedWidth}%, ${color2} ${completedWidth}%, ${color2} ${uncompletedWidth}%)`,
                    color: stageCompleted ? "white" : "black",
                    clipPath: index === 0 ? first : all,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {step.title}
                </div>
              </div>
            );
          })}
        </div>
        <Cards
          roadmap={roadmap}
          onClickOpenCardDialog={handleClickOpenCardDialog}
        />
      </div>
      <Dialog
        open={editDevice}
        onClose={() => setEditDevice(false)}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Edit Device</DialogTitle>
        <DialogContent className="flex flex-col gap-y-4">
          {/* <Alert severity="info">
            Device changes will not be reflected in the technical file form
            builder. Please update the device name in the form builder
            separately.
          </Alert> */}
          <TextField
            label="Device Name"
            fullWidth
            value={updatedDevice?.name ?? device.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUpdatedDevice({ ...device, name: event.target.value })
            }
          />
          <TextField
            label="Device Description"
            fullWidth
            value={updatedDevice?.description ?? device.description}
            multiline
            minRows={5}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUpdatedDevice({ ...device, description: event.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setEditDevice(false);
              setUpdatedDevice(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!updatedDevice}
            variant="contained"
            color="success"
            onClick={() => {
              setEditDevice(false);
              updatedDevice && updateDevice.mutate(updatedDevice);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {!!selectedCard && !!card && (
        <TaskModal
          cardConfig={{ card, phase: selectedCard.phase }}
          open={!!selectedCard}
          deviceId={deviceId}
          handleCloseCardDialog={handleCloseCardDialog}
        />
      )}
    </div>
  );
};
