import { Router, SchoolRounded } from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../config";
import { useCurrentPath } from "../../hooks";
import DeviceNavbar from "../DeviceNavbar/DeviceNavbar";
import { FixedToolbarSpacer } from "./FixedToolbarSpacer";

const MENU_ITEMS = [
  {
    name: "Device Library",
    path: ROUTES.DEVICE_MANAGEMENT,
    icon: <Router />,
  },
  {
    name: "Help Center",
    path: ROUTES.HELP_CENTER,
    icon: <SchoolRounded />,
    disabled: true,
  },
];

interface Props {
  open: boolean;
  onHandleDrawer: () => void;
  width: number;
  collapsedWidth: number;
  hasBanner: boolean;
}

export const LeftDrawer = observer((props: Props) => {
  const currentPath = useCurrentPath();
  const { pathname } = useLocation();

  const { deviceId = "" } = useParams();
  const { open, width, collapsedWidth, hasBanner } = props;

  return (
    <Drawer
      sx={() => ({
        width: open ? width : collapsedWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        "& .MuiDrawer-paper": {
          width: open ? width : collapsedWidth,
          boxSizing: "border-box",
          overflowX: !open ? "hidden" : "visible",
        },
      })}
      PaperProps={{
        className:
          "border-r border-t-0 border-b-0 border-l-0 border-solid border-slate-200",
      }}
      variant="permanent"
      hideBackdrop
      anchor="left"
      open={open}
    >
      <FixedToolbarSpacer hasBanner={hasBanner} />

      <Box>
        <List>
          {MENU_ITEMS.map(({ name, path, icon, disabled }) => (
            <Tooltip
              title={`${name} ${disabled ? "- coming soon" : ""}`}
              disableHoverListener={open}
              // followCursor
              placement="right"
              key={name}
            >
              {/* By default disabled elements like <button> do not trigger user interactions so a Tooltip will not activate on normal events like hover. To accommodate disabled elements, add a simple wrapper element, such as a span. (https://mui.com/material-ui/react-tooltip/#disabled-elements) */}
              <span>
                <ListItemButton
                  key={name}
                  selected={path === currentPath}
                  // className="pl-6"
                  disabled={disabled}
                  component={Link}
                  to={path}
                  state={{ previous: pathname }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              </span>
            </Tooltip>
          ))}
        </List>
        {deviceId && (
          <>
            <Divider className="mx-4" />
            <DeviceNavbar disableTooltip={open} />
          </>
        )}
      </Box>
    </Drawer>
  );
});
