export const config = {
  auth: {
    issuerBaseUrl: window._env_.AUTH0_ISSUER_BASE_URL || "",
    clientId: window._env_.AUTH0_CLIENT_ID || "",
    audience: window._env_.AUTH0_AUDIENCE || "",
  },
  backends: {
    default: {
      baseUrl: window._env_.BACKEND_BASE_URL || "",
    },
    assistant: {
      baseUrl: window._env_.ASSISTANT_API_BASE_URL || "",
    },
  },
};

// export type Config = typeof config;

export default config;
