import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../../assets/done.json";
import Flow from "../../assets/figmaprint.svg";

const OnboardingModal = ({
  handleOnboardingGetStarted,
}: {
  handleOnboardingGetStarted: () => void;
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h1" id="modal-modal-title">
          Welcome To FormlyAI
        </Typography>
        <DialogContentText id="alert-dialog-description" className="pt-2">
          A crystal clear path to your CE mark.
        </DialogContentText>
      </DialogTitle>
      <DialogContent className="flex items-center justify-center">
        <img src={Flow} alt="flow" className="max-w-fit min-w-0" />
        <div className="flex flex-col items-center grow-0 min-w-60">
          <DialogContent>
            <Lottie options={defaultOptions} width="60%" />
            <Typography variant="body1" fontWeight="bolder" textAlign="center">
              Complete the roadmap and get your CE mark!
            </Typography>
          </DialogContent>
          <Button variant="contained" onClick={handleOnboardingGetStarted}>
            Get Started
          </Button>
          <DialogContentText className="text-center text-xs mt-2 w-3/4">
            By clicking this button I accept the <br />
            <a href="https://formly.ai/terms-and-conditions" target="_blank">
              Terms and Conditions
            </a>
          </DialogContentText>
          {/* <DialogActions>
            <Button variant="outlined">Watch a walkthrough recording</Button>
          </DialogActions>*/}
        </div>
      </DialogContent>
    </>
  );
};

export default OnboardingModal;
