import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

let getAccessTokenSilently: getAccessTokenSilentlyType = () =>
  Promise.resolve("");

export const authHelper = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (func: getAccessTokenSilentlyType) =>
    (getAccessTokenSilently = func),
};

type getAccessTokenSilentlyType = (
  options?: GetTokenSilentlyOptions
) => Promise<string>;
