import { Skeleton } from "@mui/material";

export const RoadmapSkeleton = () => {
  return (
    <div className="flex flex-col gap-y-3">
      <Skeleton key="Title" variant="rounded" height={40} width={200} />
      <Skeleton key="subtitle" variant="rounded" height={29} width={100} />
      <div>
        <Skeleton key="Bar" variant="rounded" height={32} width={"100%"} />
        <div className="mt-3 flex gap-x-2">
          {Array.from({ length: 5 }).map((_, i) => (
            <div
              key={i.toString()}
              className="flex flex-1 gap-y-2 flex-col w-1/5 h-full"
            >
              {Array.from({ length: 4 }).map((_, i2) => (
                <Skeleton
                  key={i + "_" + i2}
                  variant="rounded"
                  height={112}
                  width={"100%"}
                  className="flex mr-[6px] rounded-sm p-3 gap-x-2 "
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
