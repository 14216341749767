import { useGetUser } from "@hooks";
import { Device, Plan } from "@models";
import { ChevronRight, Delete } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getSubscription } from "@utils";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { getDevicesQueryKey, ROUTES } from "../../config";
import { useStore } from "../../stores";

type Props = {
  devices: Device[];
};

const DeviceList = ({ devices }: Props) => {
  const { deviceStore } = useStore();
  const { data: user } = useGetUser();
  const subscription = user ? getSubscription(user) : null;

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const deleteDevice = useMutation({
    mutationFn: (id: string) => deviceStore.deleteDevice(id),
    onMutate: (id) => {
      queryClient.setQueryData(
        getDevicesQueryKey(),
        (old: Device[] | undefined) => {
          return old?.filter((device) => device.id !== id);
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getDevicesQueryKey() });
    },
  });

  const confirmationToDelete = (id: string) => {
    // confirmation popup
    let confirmed = window.confirm(
      "Are you sure you want to delete this device? All related data will be lost."
    );
    {
      confirmed && deleteDevice.mutate(id);
    }
  };

  const handleContinue = async (device: Device) => {
    navigate(ROUTES.DEVICE_OVERVIEW.replace(":deviceId", device.id));
  };

  return (
    <div data-testid="DeviceList" className="flex flex-col gap-y-3">
      {devices.map((device) => {
        return (
          <div
            key={device.id}
            onClick={() => handleContinue(device)}
            className="flex w-full cursor-pointer items-center justify-between rounded border border-solid border-gray-200 bg-white p-5 hover:bg-zinc-50"
          >
            <Typography variant="body1">{device.name}</Typography>
            <div className="flex items-center gap-x-2">
              <IconButton>
                <ChevronRight />
              </IconButton>
              {subscription &&
                subscription.product !== Plan.FREE &&
                devices.length > 1 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      confirmationToDelete(device.id);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(DeviceList);
