import { Skeleton } from "@mui/material";

export const MultiStepFormSkeleton = () => {
  return (
    <div className="flex h-full flex-1 flex-col  gap-y-2">
      <Skeleton
        key={"toolbar-skel"}
        variant="rounded"
        width="100%"
        height={37}
        className="mb-2"
      />

      <div className="flex flex-col gap-y-2">
        <Skeleton
          key={"title-skel"}
          variant="rounded"
          width="20%"
          height={55}
          className="mb-2"
        />
        <Skeleton
          key={"1"}
          variant="rounded"
          width="34%"
          height={40}
          className="mb-3"
        />
        <Skeleton key={"2"} variant="rounded" width="100%" height={52} />
        <div className="mt- flex gap-x-3">
          <Skeleton key={"3"} variant="rounded" width="50%" height={150} />
          <Skeleton key={"4"} variant="rounded" width="50%" height={150} />
        </div>
      </div>
    </div>
  );
};
