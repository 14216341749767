import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { BaseUser } from "../../../../../../../stores/models";

export type SearchAssigneesProps = {
  users: BaseUser[];
  selectedUsers: Array<BaseUser | null>;
  setSelectedUsers: (users: Array<BaseUser | null>) => void;
};

export const SearchAssignees = ({
  users,
  selectedUsers,
  setSelectedUsers,
}: SearchAssigneesProps) => {
  //TODO this is going to be changed when we start using the functional groups
  return [1, 2].map((_, idx) => (
    <Autocomplete
      disablePortal
      options={users.filter(
        (user) =>
          // Show the selected user in the dropdown and all other users that are not selected
          selectedUsers[idx]?.id === user.id ||
          selectedUsers.every((u) => u?.id !== user.id)
      )}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          key={`search-${idx}`}
          label={`Select Approver #${idx + 1}`}
          autoComplete="off"
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      // defaultValue={savedApprovers[idx].user || null}
      value={selectedUsers[idx]}
      onChange={(_, newValue) => {
        const newSelectedUsers = [...selectedUsers];
        newSelectedUsers.splice(idx, 1, newValue);
        setSelectedUsers(newSelectedUsers);
      }}
      renderOption={(props, option) => (
        <li {...props} key={`li-${idx}`}>
          <div className="flex gap-3">
            <div className="flex items-center justify-center">
              <PermIdentityIcon />
            </div>
            <div>
              <Typography variant="body1" fontWeight="500">
                <span>{`${option.firstName} ${option.lastName}`}</span>
              </Typography>
              <div>{option.email}</div>
            </div>
          </div>
        </li>
      )}
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      key={`search-${idx}`}
    />
  ));
};

export default SearchAssignees;
