import { Tooltip } from "@mui/material";
import { TemplateAssistant } from "../../../types";
import { TemplateTreeItem, TemplateTreeItemProps } from "./TemplateTreeItem";

export const ToolTipTemplateTreeItem = ({
  template,
  title,
  ...templateTreeItemProps
}: {
  template: TemplateAssistant;
  title?: string;
} & TemplateTreeItemProps) => {
  return (
    <Tooltip title={title} key={`${template.id}-tooltip`} followCursor>
      {/* <span> necessary for tooltip with disabled=true */}
      <span>
        <TemplateTreeItem template={template} {...templateTreeItemProps} />
      </span>
    </Tooltip>
  );
};
