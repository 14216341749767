import { Skeleton } from "@mui/material";

export const TreeViewSkeleton = () => {
  return (
    <div className="ml-2 flex flex-col">
      {Array.from({ length: 8 }).map((_, i2) => (
        <Skeleton
          key={"TreeViewItem_" + i2}
          variant="rounded"
          height={24}
          width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
          className="m-1"
        />
      ))}
    </div>
  );
};

export default TreeViewSkeleton;
