import {
  CloudUploadOutlined,
  ErrorOutline,
  UploadFileOutlined,
} from "@mui/icons-material";

import { BaseAnimation } from "./BtnAnimation";

const FileUpload = ({
  uploadStatus,
  handleFileChange,
}: {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  uploadStatus: "error" | "idle" | "success" | "pending";
}) => {
  if (uploadStatus === "error") {
    return (
      <BaseAnimation
        Icon={ErrorOutline}
        text="There was an error uploading the document."
        subText="Please make sure the document is a valid file type and size to try again. Maximum file size 50mb, allowed types: pdf, txt, docx, png, jpeg."
        buttonText={"Upload Again"}
        onChangeInput={handleFileChange}
        ButtonIcon={CloudUploadOutlined}
      />
    );
  }

  return (
    <BaseAnimation
      Icon={UploadFileOutlined}
      text="Upload a document."
      subText="Select a document from your device to upload. Maximum file size 50mb, allowed types: pdf, txt, docx, png, jpeg."
      isLoading={uploadStatus === "pending"}
      buttonText={"Add a Document"}
      onChangeInput={handleFileChange}
      ButtonIcon={CloudUploadOutlined}
    />
  );
};

export default FileUpload;
