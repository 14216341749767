import { Typography } from "@mui/material";

export const SectionContainer = ({
  title,
  content,
}: {
  title: string;
  content: React.ReactNode;
}) => {
  return (
    <div className="flex flex-col gap-.5">
      <Typography variant="body1" className="pb-2" fontWeight="bold">
        {title}
      </Typography>
      {content}
    </div>
  );
};

export default SectionContainer;
