import { matchRoutes, useLocation } from "react-router-dom";
import { ROUTES } from "../config";

// Props to https://stackoverflow.com/questions/66265608/react-router-v6-get-path-pattern-for-current-route
export const useCurrentPath = () => {
  const routes = Object.values(ROUTES).map((r) => ({ path: r }));
  const location = useLocation();
  const route = matchRoutes(routes, location);

  return route?.at(0)?.route?.path;
};
