import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import {
  Chip,
  ChipOwnProps,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { BaseUser } from "../../../../../stores/models";

export const StatusChip = ({
  person: user,
  loading = false,
  text,
  color,
}: {
  text: string;
  color: ChipOwnProps["color"];
  person: BaseUser;
  loading?: boolean;
}) => {
  if (loading) {
    return (
      <div className="flex flex-col gap-.5 items-center justify-center w-fit">
        <Skeleton
          variant="rectangular"
          height={32}
          width={110}
          className="rounded-2xl"
        />
        <Skeleton
          variant="rectangular"
          height={10.5}
          width={120}
          className="mt-2"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-.5 items-center justify-center w-fit">
      <Tooltip title={user.email} placement="top">
        <Chip
          icon={<PermIdentityIcon />}
          label={`${user.firstName} ${user.lastName}`}
          color={color}
        />
      </Tooltip>
      <Typography variant="caption">{text}</Typography>
    </div>
  );
};

export default StatusChip;
