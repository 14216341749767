import { Button } from "@mui/material";
import {
  BaseUser,
  DocumentVersionApprover,
} from "../../../../../stores/models";
import ApproversTabContentLoading from "./ApproversTabContentLoading";
import AssignApprovers from "./assignApprovers/AssignApprovers";
import RevisionSummary from "./revisionSummary/RevisionSummary";

type AuthorContentProps = {
  author: BaseUser;
  enableApproversEditing: boolean;
  handleSave: (
    savedApprovers: DocumentVersionApprover[],
    selectedUsers: BaseUser[],
    revisionSummary: string
  ) => void;
  revisionSummary: string;
  saving: boolean;
  savedApprovers: DocumentVersionApprover[];
  savedRevisionSummary?: string;
  selectedUsers: Array<BaseUser | null>;
  setEnableApproversEditing: (b: boolean) => void;
  setRevisionSummary: (s: string) => void;
  setSelectedUsers: (users: Array<BaseUser | null>) => void;
};

export const AuthorContent = ({
  author,
  enableApproversEditing,
  handleSave,
  revisionSummary,
  saving,
  savedApprovers,
  savedRevisionSummary,
  selectedUsers,
  setEnableApproversEditing: setShouldEnableEditing,
  setRevisionSummary,
  setSelectedUsers,
}: AuthorContentProps) => {
  if (author.organizationId === undefined) {
    return <ApproversTabContentLoading />;
  }

  const areApproversValid =
    selectedUsers.every((u) => u !== null) &&
    selectedUsers.length === 2 &&
    selectedUsers[0]?.id !== selectedUsers[1]?.id;
  const isSummaryValid = !!revisionSummary;
  const assigneesChanged = selectedUsers.some((u) =>
    savedApprovers.every((a) => a.user.id !== u?.id)
  );
  const summaryChanged = revisionSummary !== savedRevisionSummary;
  const approvalConfigChanged = assigneesChanged || summaryChanged;

  const saveEnabled =
    approvalConfigChanged && areApproversValid && isSummaryValid && !saving;

  return (
    <>
      <div className="flex flex-col gap-4 mb-4 overflow-auto">
        <AssignApprovers
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          enableApproversEditing={enableApproversEditing}
          setEnableApproversEditing={setShouldEnableEditing}
          organizationId={author.organizationId}
          userId={author.id}
          approvers={savedApprovers}
          saving={saving}
        />
        <RevisionSummary
          revisionSummary={revisionSummary}
          setRevisionSummary={setRevisionSummary}
        />
      </div>
      <Button
        variant="contained"
        disabled={!saveEnabled}
        onClick={() =>
          handleSave(
            savedApprovers,
            selectedUsers.filter((u) => u !== null),
            revisionSummary
          )
        }
        size="large"
      >
        Submit
      </Button>
    </>
  );
};

export default AuthorContent;
