import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactElement, ReactNode } from "react";

type Props = {
  abortText?: string;
  confirmText?: string;
  content: string | ReactElement;
  onAbort: () => void;
  onConfirm?: () => void;
  open: boolean;
  title: string;
  children?: ReactNode;
  confirmButtonProps?: {
    component?: string;
  };
};

export function ConfirmationModal({
  children,
  abortText = "Abort",
  confirmText = "Confirm",
  content,
  onAbort,
  onConfirm: onClick,
  confirmButtonProps,
  open,
  title,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onAbort}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onAbort} variant="outlined" color="inherit">
          {abortText}
        </Button>
        <Button
          {...(onClick && { onClick })}
          component="button"
          color="error"
          variant="contained"
          {...confirmButtonProps}
        >
          {confirmText}
          {children}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
