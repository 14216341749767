import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormControlLabel as MuiFormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";

import { useCreateDeviceMutation } from "@hooks";
import { Device, Plan, SimpleSubscription, User } from "@models";
import { isPaidDeviceCreation } from "@utils";
import { Link } from "react-router-dom";
import { ROUTES } from "src/config";
import { NewDevice } from "../../types";

const questions: Array<{
  question: string;
  statement: string;
  id: keyof NewDevice["questionnaire"];
}> = [
  {
    question: "Does your product come in contact with the human body?",
    statement: "The product comes in contact with the human body.",
    id: "humanContact",
  },
  {
    question: "Does your product use software?",
    statement: "The product uses software",
    id: "usesSoftware",
  },
  {
    question: "Does your product incorporate artificial intelligence?",
    statement: "The product incorporates artificial intelligence.",
    id: "incorporatesAI",
  },
  {
    question:
      "Does the product you intended to certify only a software application without physical parts?",
    statement: "The product is a software only application.",
    id: "softwareOnly",
  },
  {
    question:
      "Does your product incorporate cells from human origin or their derivatives?",
    statement:
      "The product incorporates cells from human origin or their derivatives.",
    id: "incorporatesHumanCells",
  },
  {
    question:
      "Does your product incorporate cells from animal origin or their derivatives?",
    statement:
      "The product incorporates cells from animal origin or their derivatives.",
    id: "incorporatesAnimalCells",
  },
  {
    question:
      "Is your product intended to be sterilized or provided in a defined microbiological condition?",
    statement:
      "The product is intended to be sterilized or provided in a defined microbiological condition.",
    id: "sterilizationIntended",
  },
  {
    question:
      "Does your product contain known carcinogenic, mutagenic or toxic to reproduction (CMR) substances or known endocrine-disrupting substances?",
    statement:
      "The product contains known carcinogenic, mutagenic or toxic to reproduction (CMR) substances or known endocrine-disrupting substances.",
    id: "containsCmr",
  },
  {
    question:
      "Is your product intended to be reprocessed or is not a single use product?",
    statement:
      "The product is intended to be reprocessed or is not a single use product.",
    id: "reprocessingIntended",
  },
  {
    question: "Does your product contain electrical parts?",
    statement: "The product contains electrical parts.",
    id: "electricalParts",
  },
  {
    question: "Is your product Class IIa or above according to MDR 2017/745?",
    statement: "The product is Class IIa or above according to MDR 2017/745.",
    id: "classTwo",
  },
  {
    question:
      "Does your product incorporate a medicinal substance as an integral part of the device?",
    statement:
      "The product incorporates a medicinal substance as an integral part of the device.",
    id: "incorporatesMedicinalSubstances",
  },
  {
    question:
      "Is your product a 3D printed and/or custom-made product for each patient?",
    statement:
      "The product is a 3D printed and/or custom-made product for each patient.",
    id: "customMade",
  },
];

export const DeviceCreationModal = ({
  open,
  onClose,
  subscription,
  user,
  devices,
}: {
  open: boolean;
  onClose: () => void;
  subscription: SimpleSubscription;
  user: User;
  devices: Device[];
}) => {
  const newDeviceInitialState: NewDevice = {
    name: "",
    description: "",
    questionnaire: {
      humanContact: false,
      usesSoftware: false,
      incorporatesAI: false,
      incorporatesHumanCells: false,
      incorporatesAnimalCells: false,
      sterilizationIntended: false,
      containsCmr: false,
      reprocessingIntended: false,
      electricalParts: false,
      classTwo: false,
      incorporatesMedicinalSubstances: false,
      customMade: false,
      softwareOnly: false,
    },
  };
  const [newDevice, setNewDevice] = useState<NewDevice>(newDeviceInitialState);

  const createDeviceMutation = useCreateDeviceMutation();

  const [billingConfirmed, setBillingConfirmed] = useState(false);

  const isFreePlan = subscription.product === Plan.FREE;

  const isPaidCreation = isPaidDeviceCreation(user, devices.length);

  if (isFreePlan && devices.length >= 1) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Device limit reached</DialogTitle>
        <DialogContent className="flex flex-col gap-y-4">
          <Alert severity="warning">
            The free plan allows you to create only one device. Please upgrade
            to a paid plan to create additional devices.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            component={Link}
            to={ROUTES.SUBSCRIPTION}
            variant="contained"
            color="primary"
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Device</DialogTitle>
      <DialogContent className="flex  flex-col gap-y-4">
        <TextField
          label="Device Name"
          fullWidth
          value={newDevice?.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setNewDevice({ ...newDevice, name: event.target.value })
          }
        />
        <Alert severity="info">
          Please provide a brief description of your device. Please be as
          specific as possible. Every small detail, like special features,
          materials, or the production process, can be essential in order to
          give you the best possible suggestions in the future.
        </Alert>
        <TextField
          label="Description"
          fullWidth
          multiline
          minRows={3}
          value={newDevice?.description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setNewDevice({ ...newDevice, description: event.target.value });
          }}
        />
        <Alert severity="info">
          Please answer the questions below thoroughly. We will tailor your
          individual CE marking process based on your answers.
        </Alert>

        <FormControl className="flex flex-col gap-y-4">
          {questions.map((question) => (
            <FormControlLabel
              className="flex justify-between"
              key={question.id}
              control={
                <Switch
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setNewDevice({
                      ...newDevice,
                      questionnaire: {
                        ...newDevice.questionnaire,
                        [question.id]: event.target.checked,
                      },
                    })
                  }
                  checked={!!newDevice.questionnaire[question.id]}
                />
              }
              label={question.question}
              labelPlacement="start"
            />
          ))}
        </FormControl>
      </DialogContent>
      <DialogActions className="flex gap-x-4">
        {isPaidCreation && (
          <MuiFormControlLabel
            control={
              <Checkbox
                className="ml-1"
                checked={billingConfirmed}
                onChange={(e) => setBillingConfirmed(e.target.checked)}
              />
            }
            label="I confirm that I will be billed for this additional device"
            labelPlacement="start"
          />
        )}
        <LoadingButton
          disabled={
            !newDevice.name ||
            !newDevice.description ||
            (isPaidCreation && !billingConfirmed)
          }
          variant="contained"
          color="success"
          loading={createDeviceMutation.isPending}
          onClick={() => createDeviceMutation.mutate({ device: newDevice })}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
