import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

interface SnackbarContextProps {
  open: boolean;
  message: string;
  showSnackbar: (title: string) => void;
  handleClose: (
    event: Event | React.SyntheticEvent<any, Event>,
    reason: string
  ) => void;
  hideSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextProps | null>(null);

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarProvider"
    );
  }
  return context;
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const showSnackbar = useCallback((title: string) => {
    setMessage(title);
    setOpen(true);
  }, []);

  const hideSnackbar = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClose = useCallback(
    (_: Event | React.SyntheticEvent<any>, reason: string) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    },
    []
  );

  return (
    <SnackbarContext.Provider
      value={{ open, message, showSnackbar, handleClose, hideSnackbar }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const { showSnackbar, hideSnackbar } = useSnackbarContext();
  return { showSnackbar, hideSnackbar };
};
