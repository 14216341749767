import * as yup from "yup";
import { date, mixed, object, string } from "yup";

export enum TEMPLATE_TYPE {
  ADDITIONAL_SOFTWARE_TEST_PLANS = "additional-software-test-plans",
  ADDITIONAL_SOFTWARE_TEST_REPORTS = "additional-software-test-reports",
  AUDIT_PLAN = "audit-plan",
  AUDIT_PROGRAM = "audit-program",
  AUDIT_REPORT = "audit-report",
  BUG_FIX_REPORT = "bug-fix-report-template",
  CAPA_PLAN = "capa-plan",
  CAPA_REPORT = "capa-report",
  CHANGE_EVALUATION_REPORT = "change-evaluation-report",
  CHANGE_REQUEST = "change-request",
  CHECKLIST_SOFTWARE_RELEASE = "checklist-software-release",
  CLINICAL_EVALUATION_PLAN = "clinical-evaluation-plan",
  CLINICAL_EVALUATION_REPORT = "clinical-evaluation-report",
  DECLARATION_OF_CONFORMITY = "eu-declaration-of-conformity",
  DEPLOYMENT_EVALUATION_CHECKLIST = "deployment-evaluation-checklist",
  DEVICE_LABEL = "device-label",
  FIELD_SAFETY_NOTICE = "field-safety-notice-form",
  GROUP_TRAINING_RECORD = "group-training-record",
  GSPR_CHECKLIST = "gspr-checklist",
  INCIDENT_ASSESSMENT_FORM = "incident-assessment-form",
  INSTRUCTIONS_FOR_USE = "instructions-for-use",
  INTENDED_USE = "intended-use",
  LIST_CAPA = "list-of-capas",
  LIST_OF_EMPLOYEE_TRAINING = "list-of-employee-training",
  LIST_OF_KNOWN_ANOMALIES = "list-of-known-anomalies",
  LIST_OF_MEDICAL_DEVICES = "list-of-medical-devices",
  LIST_OF_QUALIFIED_SUPPLIERS = "list-of-qualified-suppliers",
  LIST_OF_REGULATORY_REQUIREMENTS = "list-of-regulatory-requirements",
  LIST_OF_VALIDATED_SOFTWARE = "list-of-validated-software",
  MANAGEMENT_REVIEW_REPORT = "management-review-report",
  MARKETING_CONTENT = "marketing-content",
  MDR_DEVICE_CLASSIFICATION = "mdr-device-classification",
  OTHER_LANGUAGES_TRANSLATED_IFUS = "other-languages-translated-ifus",
  PERIODIC_SAFETY_UPDATE_REPORT = "periodic-safety-update-report",
  PHYSICIANS_HANDBOOK = "physicians-handbook",
  POST_MARKET_CLINICAL_FOLLOW_UP_PLAN = "post-market-clinical-follow-up-plan",
  POST_MARKET_CLINICAL_FOLLOW_UP_REPORT = "post-market-clinical-follow-up-report",
  POST_MARKET_SURVEILLANCE_PLAN = "post-market-surveillance-plan",
  POST_MARKET_SURVEILLANCE_REPORT = "post-market-surveillance-report",
  PRIMARY_LANGUAGE_TRANSLATED_IFU = "primary-language-translated-ifu",
  QUALITY_MANUAL_POLICY_OBJECTIVES = "quality-manual-policy-objectives",
  RELEASE_NOTES = "release-notes",
  REQUIRED_DOCUMENTATION_TRAINING_BY_FUNCTIONAL_GROUP = "required-documentation-training-by-functional-group",
  RISK_ASSESSMENT = "risk-assessment",
  RISK_MANAGEMENT_PLAN = "risk-management-plan",
  RISK_MANAGEMENT_REPORT = "risk-management-report",
  SOFTWARE_ARCHITECTURE = "software-architecture",
  SOFTWARE_ARCHITECTURE_CHECKLIST = "software-architecture-checklist",
  SOFTWARE_DEVELOPMENT_AND_MAINTENANCE_PLAN = "software-development-and-maintenance-plan",
  SOFTWARE_REQUIREMENTS_LIST = "software-requirements-list",
  SOFTWARE_REQUIREMENTS_REVIEW_CHECKLIST = "software-requirements-checklist",
  SOFTWARE_SYSTEM_TEST_REPORT = "software-system-test-report",
  SOFTWARE_TEST_PLAN = "software-system-test-plan",
  SOFTWARE_VALIDATION_FORM = "software-validation-form",
  SOP_CAPA = "sop-capa",
  SOP_CHANGE_MANAGEMENT = "sop-change-management",
  SOP_CLINICAL_EVALUATION = "sop-clinical-evaluation",
  SOP_DEPLOYMENT = "sop-deployment",
  SOP_DOCUMENT_RECORD_CONTROL = "sop-document-record-control",
  SOP_FEEDBACK_MANAGEMENT = "sop-feedback-and-complaint-management",
  SOP_HUMAN_RESOURCES_ADMINISTRATION = "sop-human-resources-administration",
  SOP_INTEGRATED_SOFTWARE_DEVELOPMENT = "sop-integrated-software-development",
  SOP_INTERNAL_AUDIT = "sop-internal-audit",
  SOP_MANAGEMENT_REVIEW = "sop-management-review",
  SOP_POST_MARKET_SURVEILLANCE = "sop-post-market-surveillance",
  SOP_PRODUCT_REGISTRATION_AND_CERTIFICATION = "sop-product-registration-and-certification",
  SOP_PURCHASING = "sop-purchasing",
  SOP_RISK_MANAGEMENT = "sop-risk-management",
  SOP_SALES = "sop-sales",
  SOP_SOFTWARE_PROBLEM_RESOLUTION = "sop-software-problem-resolution",
  SOP_SOFTWARE_VALIDATION = "sop-software-validation",
  SOP_UPDATE_OF_REGULATIONS = "sop-update-of-regulations",
  SOP_VIGILANCE = "sop-vigilance",
  SOUP_LIST = "soup-list",
  SUPPLIER_CHECKLIST = "supplier-checklist",
  TECHNICAL_FILE = "technical-file",
  UDI_CREATION = "udi-creation",
  USABILITY_EVALUATION_PLAN = "usability-evaluation-plan",
  USABILITY_EVALUATION_PROTOCOL = "usability-evaluation-protocol",
  USABILITY_EVALUATION_REPORT = "usability-evaluation-report",
  USER_MANUAL = "user-manual",
  USER_NEEDS_CHECKLIST = "user-needs-review-checklist",
  USER_NEEDS_LIST = "user-needs-list",
}

// LEGACY CODE.
export const templateDocumentSchema = object({
  id: string().required(),
  type: mixed<TEMPLATE_TYPE>()
    .required()
    .test((value) =>
      Object.values(TEMPLATE_TYPE).includes(value as TEMPLATE_TYPE)
    ),
  content: string().defined(),
  deviceId: string().required(),
  createdBy: string().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
});

export interface TemplateDocument
  extends yup.InferType<typeof templateDocumentSchema> {}
