import { Chip, Typography } from "@mui/material";
import { DocStatus } from "../../../../../types";

const SelectableOption = ({
  label,
  type,
  showStatus = false,
  disabled = false,
}: {
  label: string;
  type?: DocStatus;
  showStatus?: boolean;
  disabled?: boolean;
}) => {
  const color =
    type === "DRAFT" ? "warning" : type === "LIVE" ? "success" : "default";
  const status =
    type === "DRAFT" ? "Draft" : type === "LIVE" ? "Live" : "Archived";
  return (
    <div className="flex items-center gap-2 text-sm">
      {showStatus && (
        <Chip
          label={status}
          variant="filled"
          color={disabled ? "default" : color}
          size="small"
        />
      )}
      <Typography variant="body1">{label}</Typography>
    </div>
  );
};

export default SelectableOption;
