import { useEffect } from "react";

export const useKeyboardShortcuts = (
  keyToFunctionMap: Record<string, () => void>
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const keyFunction = keyToFunctionMap[event.key];
      if (keyFunction) {
        keyFunction();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [keyToFunctionMap]);
};
