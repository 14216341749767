import { Document, DocumentVersion, User } from "@models";
import { useEffect, useState } from "react";
import { handleOutstandingAction } from "../helpers/handleOutstandingAction/handleOutstandingAction";
import {
  OutstandingAction,
  TOutstandingAction,
} from "../helpers/handleOutstandingAction/handleOutstandingAction.types";

export default function useOutstandingAction({
  document,
  selectedDocVersionId,
  user,
  setDocInfoDrawerOpen,
  selectedDocVersion,
}: {
  document: Document | undefined | null;
  selectedDocVersionId: string | undefined;
  user: User | undefined;
  setDocInfoDrawerOpen: (value: boolean) => void;
  selectedDocVersion: DocumentVersion | undefined;
}) {
  const [outstandingAction, setOutstandingAction] =
    useState<TOutstandingAction | null>(null);
  const shouldShow =
    outstandingAction !== OutstandingAction.ALL_DONE &&
    outstandingAction !== null;

  useEffect(() => {
    if (document && user && selectedDocVersion) {
      handleOutstandingAction({
        user,
        outstandingAction,
        setDocInfoDrawerOpen,
        setOutstandingAction,
        selectedDocVersion,
      });
    }
  }, [document, selectedDocVersionId, user]);

  const reset = () => {
    setDocInfoDrawerOpen(false);
    setOutstandingAction(null);
  };
  return { shouldShow, setOutstandingAction, reset };
}
