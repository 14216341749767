import { ChevronRight, ContentCopy, ReplayRounded } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { chain } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Device, DocumentVersion, TEMPLATE_TYPE } from "../../stores/models";
import { DocumentDataKey, SuggestionState, TemplateElement } from "../../types";

type Props = {
  suggestions: SuggestionState;
  step: TemplateElement;
  device: Device;
  documentVersion: DocumentVersion;

  handleApply: (stepId: DocumentDataKey, suggestion: string) => void;
  onRegenerateSuggestion: () => void;
};

export const Suggestion = ({
  step,
  suggestions,
  handleApply,
  onRegenerateSuggestion,
  documentVersion,
}: Props) => {
  const { templateId = "" } = useParams<{
    deviceId: string;
    templateId: TEMPLATE_TYPE;
  }>();
  if (!templateId) {
    throw new Error("No template id specified");
  }

  const [copySuccess, setCopySuccess] = useState(false);
  const suggestionState = suggestions[step.id];
  if (!suggestionState) {
    throw new Error(
      "Error trying to access suggestion. A suggestion with the given id does not exist in the state."
    );
  }
  const {
    value: localSuggestion,
    loading,
    isStatusMessage,
    statusMessageType,
  } = suggestionState;

  const savedSuggestion = chain(documentVersion.suggestions)
    .filter((s) => s.element === step.id)
    .orderBy("createdAt")
    .last()
    .value()?.suggestion;

  let suggestion = localSuggestion;
  if (!suggestion && savedSuggestion) {
    suggestion = savedSuggestion;
  }

  const copySuggestionToClipboard = async (suggestion: string) => {
    const blobHtml = new Blob([suggestion], { type: "text/html" });
    const blobText = new Blob([suggestion], { type: "text/plain" });
    const data = [
      new ClipboardItem({
        ["text/plain"]: blobText,
        ["text/html"]: blobHtml,
      }),
    ];

    navigator.clipboard.write(data);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const hasError = suggestionState.error;

  return (
    <div className="flex w-1/2  flex-col">
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center justify-between">
          <Typography variant="body1" className="font-bold">
            Suggestion
          </Typography>
          <div>
            <Tooltip title="Regenerate suggestion" placement="left">
              <IconButton size="small" onClick={onRegenerateSuggestion}>
                <ReplayRounded />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={copySuccess ? "Copied" : "Copy suggestion"}
              placement="left"
            >
              <IconButton
                size="small"
                onClick={() => copySuggestionToClipboard(suggestion)}
              >
                <ContentCopy />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      {hasError ? (
        <div className=" h-[60px] rounded bg-gray-100 px-3 py-4">
          <Typography className="text-red-500">
            Failed to load the suggestion. Please contact us if the issue
            persists.
          </Typography>
        </div>
      ) : !localSuggestion && loading ? (
        <Skeleton
          variant="rectangular"
          height={60}
          className="rounded bg-gray-100"
        />
      ) : (
        <>
          <div className="border-md mb-4 rounded bg-gray-100 p-2">
            <div
              dangerouslySetInnerHTML={{
                __html: suggestion || "",
              }}
              className={`whitespace-pre-wrap ${
                isStatusMessage && statusMessageType === "error"
                  ? "text-red-500"
                  : ""
              }`}
            />
          </div>
          {["textField", "markdownTable"].includes(step.element.type) && (
            <div className="flex self-end">
              <Button
                variant="contained"
                // size="small"
                endIcon={<ChevronRight />}
                color="success"
                disabled={suggestionState.applied}
                onClick={() => handleApply(step.id, suggestion)}
              >
                Apply
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
