import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AddCircleOutline, ArrowDropDown, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Divider, Tooltip, Typography } from "@mui/material";
import { orderBy } from "lodash";
import moment from "moment";
import { useState } from "react";
import { UPGRADE_PLAN_TOOLTIP } from "src/utils/consts";
import { Document, DocumentVersion } from "../../../../../stores/models";
import { captureSentryException } from "../../../../../utils";
import { ConfirmationModal } from "../../../../ConfirmationModal";
import SelectableOption from "./SelectableOption";

const DocRevsionDropdown = ({
  docs,
  selectedDoc,
  selectedVersion,
  isCreatingNewInstance,
  onCreateDocInstance,
  onSelectDocInstance,
  isDocEnabled,
}: {
  docs?: Document[];
  docVersions: Array<DocumentVersion>;
  selectedDoc: Document;
  selectedVersion: DocumentVersion;
  onSelectDocInstance?: (doc: Document) => void;
  onSelectDocVersion?: (doc: DocumentVersion) => void;
  onCreateDocInstance?: () => void;
  showStatus?: boolean;
  isMultiInstanceDoc?: boolean;
  isCreatingNewInstance?: boolean;
  isDocEnabled: boolean;
}) => {
  const [docInstanceDropdownOpen, setDocInstanceDropdownOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const sortedDocInstances = orderBy(docs, ["createdAt"], ["desc"]);

  const getDocNumber = (doc: Document) => {
    return (
      sortedDocInstances.length -
      sortedDocInstances.findIndex((i) => i.id === doc.id)
    );
  };

  const handleNewRecordClick = () => {
    setConfirmationModalOpen(true);
  };

  const handleNewRecordConfirm = () => {
    setConfirmationModalOpen(false);
    if (onCreateDocInstance) {
      onCreateDocInstance();
    } else {
      captureSentryException(
        "Called handleNewRecordConfirm without handleNewRecordConfirm being defined"
      );
    }
  };

  const handleNewRecordCancel = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <Tooltip
      title={UPGRADE_PLAN_TOOLTIP}
      {...(isDocEnabled && { open: false })}
    >
      <div>
        <ClickAwayListener
          onClickAway={() => setDocInstanceDropdownOpen(false)}
        >
          <div className="relative z-10">
            <div
              className={`p-x-2 flex w-fit ${!isDocEnabled ? "pointer-events-none" : "cursor-pointer"} items-center justify-between rounded-l-md border-r-0 border border-solid border-gray-300 px-2 py-1.5 hover:bg-zinc-100`}
              onClick={() => {
                setDocInstanceDropdownOpen((prev) => !prev);
              }}
            >
              <SelectableOption
                label={`Record #${getDocNumber(selectedDoc)}`}
                disabled={!isDocEnabled}
              />
              <ArrowDropDown fontSize="small" />
            </div>

            <div
              className={`mt-1 flex  min-w-max flex-col overflow-auto rounded-md border border-solid border-slate-300 bg-color-bg shadow-md ${docInstanceDropdownOpen ? "absolute" : "hidden"}`}
            >
              {isDocEnabled &&
                sortedDocInstances.map((doc) => (
                  <div
                    className={`flex cursor-pointer items-center p-2 hover:bg-slate-100 ${selectedVersion.id === doc.id && "bg-slate-200"}`}
                    onClick={() => {
                      onSelectDocInstance && onSelectDocInstance(doc);
                      setDocInstanceDropdownOpen((prev) => !prev);
                    }}
                    key={`${doc.id}-selectable-option`}
                  >
                    <SelectableOption
                      label={`Record #${getDocNumber(doc)} - ${moment(doc.createdAt).fromNow()}`}
                    />
                  </div>
                ))}
              <Divider />
              <>
                <LoadingButton
                  className="rounded-none"
                  startIcon={<AddCircleOutline />}
                  loading={isCreatingNewInstance}
                  onClick={handleNewRecordClick}
                >
                  New Record
                </LoadingButton>
                <ConfirmationModal
                  open={confirmationModalOpen}
                  onAbort={handleNewRecordCancel}
                  onConfirm={handleNewRecordConfirm}
                  content={
                    <Typography>
                      You should only do this if you need a new instance of this
                      record.
                      <br />
                      If you want to make changes to the existing record you
                      should use the{" "}
                      <Edit fontSize="small" className="align-middle	" /> button
                      on the right.
                    </Typography>
                  }
                  title="New Record"
                  confirmText="New Record"
                />
              </>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </Tooltip>
  );
};

export default DocRevsionDropdown;
