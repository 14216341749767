import { BillingInterval } from "@models";
import { Chip, FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface BillingCycleSelectorProps {
  billingCycle: BillingInterval;
  setBillingCycle: React.Dispatch<React.SetStateAction<BillingInterval>>;
}

export const BillingCycleSelector: React.FC<BillingCycleSelectorProps> = ({
  billingCycle,
  setBillingCycle,
}) => {
  return (
    <div>
      <RadioGroup
        row
        aria-label="billing cycle"
        name="billingCycle"
        className="flex gap-2"
        value={billingCycle}
        onChange={(event) =>
          setBillingCycle(event.target.value as BillingInterval)
        }
      >
        <FormControlLabel
          value={BillingInterval.MONTHLY}
          control={<Radio />}
          label="Billed monthly"
        />
        <FormControlLabel
          value={BillingInterval.YEARLY}
          control={<Radio />}
          label={
            <span>
              Billed yearly{" "}
              <Chip
                label="Save 25%"
                color="success"
                size="tiny"
                className="ml-1"
              />
            </span>
          }
        />
      </RadioGroup>
    </div>
  );
};
