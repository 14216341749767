import { GroupAddOutlined, HistoryEduOutlined } from "@mui/icons-material";
import { Divider, Menu, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../config";
import { config as TemplateAssistantConfig } from "../../../config/assistant";
import {
  ReducedDocument,
  TEMPLATE_TYPE,
  UserTodos,
} from "../../../stores/models";
import { TodoCard } from "./TodoCard";

type TodosProps = {
  todos: UserTodos["documentsTasks"];
  anchorTodos: HTMLElement | null;
  isOpen: boolean;
  closeTodo: () => void;
  hasOutstandingActions: boolean;
};

export const Todos = ({
  todos,
  anchorTodos,
  isOpen,
  closeTodo,
  hasOutstandingActions,
}: TodosProps) => {
  const [urlParams, setUrlParams] = useState<{
    deviceId: string;
    templateId: string;
  }>({
    deviceId: "",
    templateId: "",
  });
  const navigate = useNavigate();
  const { templateId = "", deviceId = "" } = useParams();

  const navigateToTodo = ({ todo }: { todo: ReducedDocument }) => {
    navigate(
      ROUTES.QMS_OPEN_DOC.replace(":deviceId", todo.deviceId)
        .replace(":templateId", todo.name)
        .replace(":docId", todo.id)
    );

    setUrlParams({
      deviceId: todo.deviceId,
      templateId: todo.name,
    });
  };

  useEffect(() => {
    const hasUrlChanged =
      urlParams.deviceId !== deviceId || urlParams.templateId !== templateId;

    if (hasUrlChanged) {
      setUrlParams({
        deviceId,
        templateId,
      });
    }
  }, [templateId, deviceId]);

  const checkIfTodoIsActive = (todo: ReducedDocument) =>
    urlParams.templateId === todo.name && urlParams.deviceId === todo.deviceId;

  return (
    <Menu
      id="todos-menu"
      anchorEl={anchorTodos}
      open={isOpen}
      onClose={closeTodo}
      onClick={closeTodo}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        className: "pt-0",
      }}
      disablePortal
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            maxHeight: 300,
          },
        },
      }}
    >
      <Typography variant="body1" className="py-2 px-4">
        Todos
      </Typography>
      <Divider />
      {!hasOutstandingActions && (
        <Typography variant="body1" className="text-wrap py-2 px-4">
          Lean back and relax. Nothing to do here 😎
        </Typography>
      )}
      {todos.toApprove.map((todo: ReducedDocument) => (
        <TodoCard
          closeTodo={closeTodo}
          key={todo.id}
          text={`Approve the ${TemplateAssistantConfig[todo.name as TEMPLATE_TYPE].docName} document.`}
          icon={<HistoryEduOutlined />}
          navigateToTodo={() => navigateToTodo({ todo })}
          isActive={checkIfTodoIsActive(todo)}
        />
      ))}
      {todos.toAssignApprovers.map((todo: ReducedDocument) => (
        <TodoCard
          closeTodo={closeTodo}
          key={todo.id}
          text={`Assign approvers to the ${TemplateAssistantConfig[todo.name as TEMPLATE_TYPE].docName} document.`}
          icon={<GroupAddOutlined />}
          navigateToTodo={() => navigateToTodo({ todo })}
          isActive={checkIfTodoIsActive(todo)}
        />
      ))}
    </Menu>
  );
};
