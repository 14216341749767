import showdown from "showdown";
export const handleCopy = async (
  docString: string,
  setLoadingClipboard: React.Dispatch<React.SetStateAction<boolean>>,
  setCopySuccess: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoadingClipboard(true);
  setCopySuccess(false);
  var converter = new showdown.Converter({
    tables: true,
    moreStyling: true,
    // extensions: ["mermaid"],
  });
  const text = converter
    .makeHtml(docString)
    .replace(/<table(.*?)>/g, '<table$1 style="border: 1px solid #000">')
    //.replace(/<tr(.*?)>/g, '<tr$1 style="border: 1px solid #000">')
    .replace(/<td(.*?)>/g, '<td$1 style="border: 1px solid #000">')
    .replace(/<th(.*?)>/g, '<th$1 style="border: 1px solid #000">');

  const blobHtml = new Blob([text], { type: "text/html" });
  const blobText = new Blob([text], { type: "text/plain" });
  const data = [
    new ClipboardItem({
      ["text/plain"]: blobText,
      ["text/html"]: blobHtml,
    }),
  ];

  navigator.clipboard.write(data).then(
    () => {
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    },
    () => {}
  );
  setLoadingClipboard(false);
};
