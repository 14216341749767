import { useAuth0 } from "@auth0/auth0-react";
import { Plan } from "@models";
import {
  AssignmentOutlined,
  Person2Outlined,
  SmartToy,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Badge,
  IconButton,
  Toolbar,
  Tooltip,
  useTheme,
} from "@mui/material";
import { getSubscription } from "@utils";
import { Crisp } from "crisp-sdk-web";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Banner } from "src/components/Layout/Banner";
import Logo from "../../assets/formly-logo.svg";
import { ROUTES } from "../../config";
import { useGetUser, useGetUserTodos } from "../../hooks";
import CustomChip from "../CustomChip";
import { Todos } from "./todos/Todos";
import { UserMenu } from "./userMenu/UserMenu";

export const chipLabels = {
  [Plan.FREE]: "Free",
  [Plan.PREMIUM]: "Premium",
  [Plan.PREMIUM_PLUS]: "Premium+",
};

const NavBar = (props: Props) => {
  const { onHandleDrawer, hasBanner } = props;
  const { isAuthenticated } = useAuth0();
  const { deviceId = "" } = useParams();
  const theme = useTheme();

  const { data: userTodos } = useGetUserTodos(
    deviceId,
    isAuthenticated && !!deviceId
  );
  const { data: user } = useGetUser(isAuthenticated);

  const subscription = user ? getSubscription(user) : null;

  const [supportOpen, setSupportOpen] = useState(false);
  const [areTodosOpen, setAreTodosOpen] = useState(false);
  const [anchorTodos, setAnchorTodos] = useState<HTMLElement | null>(null);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const [anchorUserMenu, setAnchorUserMenu] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    if (!supportOpen) {
      Crisp.chat.onChatClosed(() => {
        setSupportOpen(false);
        Crisp.chat.hide();
      });

      Crisp.chat.hide();
      Crisp.chat.close();
    } else {
      Crisp.chat.show();
      Crisp.chat.open();
    }
  }, [supportOpen]);

  const hasOutstandingActions =
    userTodos?.documentsTasks.toApprove.length !== 0 ||
    userTodos.documentsTasks.toAssignApprovers.length !== 0;

  const clickTodoIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorTodos(event.currentTarget);
    setAreTodosOpen(!areTodosOpen);
  };

  const closeTodo = () => {
    setAnchorTodos(null);
    setAreTodosOpen(false);
  };

  const clickUserMenuIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorUserMenu(event.currentTarget);
    setIsUserMenuOpen(!areTodosOpen);
  };

  const closeUserMenu = () => {
    setAnchorUserMenu(null);
    setIsUserMenuOpen(false);
  };

  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      {hasBanner && subscription && (
        <Banner
          bannerText={
            "Enjoying the free trial? Upgrade to a premium plan to complete your device's CE mark."
          }
          buttonText={"Upgrade plan"}
          buttonLink={ROUTES.SUBSCRIPTION}
        />
      )}
      <Toolbar className="justify-center px-5 border-slate-200" variant="dense">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onHandleDrawer}
          className="mr-2 -ml-3"
        >
          <MenuIcon />
        </IconButton>

        <Link to={ROUTES.DEVICE_MANAGEMENT} className="flex align-middle -mb-1">
          <img src={Logo} height={24} alt="Logo" className="cursor-pointer" />
        </Link>
        {subscription && (
          <CustomChip
            label={`${chipLabels[subscription.product]}`}
            className="ml-2"
            color={
              subscription.product === "FREE"
                ? theme.palette.success.main
                : theme.palette.primary.main
            }
            size="tiny"
          />
        )}

        <div className="flex flex-1 items-end justify-end align-middle gap-x-2">
          {!!deviceId && userTodos?.documentsTasks && (
            <>
              <Tooltip title="Todos" placement="bottom">
                <IconButton
                  onClick={clickTodoIcon}
                  color="inherit"
                  size="small"
                  aria-describedby="todos-menu"
                >
                  <Badge
                    color="error"
                    invisible={!hasOutstandingActions}
                    badgeContent={
                      userTodos?.documentsTasks?.toApprove.length +
                      userTodos?.documentsTasks?.toAssignApprovers.length
                    }
                  >
                    <AssignmentOutlined
                      style={{ color: theme.palette.grey[500] }}
                    />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Todos
                todos={userTodos.documentsTasks}
                isOpen={areTodosOpen}
                closeTodo={closeTodo}
                anchorTodos={anchorTodos}
                hasOutstandingActions={hasOutstandingActions}
              />
            </>
          )}
          <Tooltip title="Support" placement="bottom">
            <IconButton
              onClick={() => setSupportOpen(!supportOpen)}
              size="small"
            >
              <Badge color="primary">
                <SmartToy style={{ color: theme.palette.grey[500] }} />
              </Badge>
            </IconButton>
          </Tooltip>
          {user && subscription && (
            <>
              <Tooltip title="User" placement="bottom">
                <IconButton
                  onClick={clickUserMenuIcon}
                  color="inherit"
                  size="small"
                  aria-describedby="user-menu"
                >
                  <Person2Outlined style={{ color: theme.palette.grey[500] }} />
                </IconButton>
              </Tooltip>
              <UserMenu
                isOpen={isUserMenuOpen}
                close={closeUserMenu}
                anchorUserMenu={anchorUserMenu}
                user={user}
              />
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export { NavBar };

type Props = {
  onHandleDrawer?: () => void;
  isDrawerOpen?: boolean;
  hasBanner: boolean;
};
