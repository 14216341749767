export enum ArticleId {
  DeviceBasicCharacteristics = "device-basic-characteristics",
  DeviceClassification = "device-classification",
  QualityManagementSystem = "quality-management-system",
  RiskManagementSystem = "risk-management-system",
}

const ArticlesConfig: ArticlesConfig = {
  [ArticleId.DeviceBasicCharacteristics]: {
    id: "5iO9fRongtP0fPSGljVhua",
    title: "Device Basic Characteristics",
  },
  [ArticleId.DeviceClassification]: {
    id: "1n7azF1fNYjI4kx247mO7j",
    title: "Device Classification",
  },
  [ArticleId.QualityManagementSystem]: {
    id: "1YDC41OjnxRsfdJ8vsE7Ax",
    title: "Quality Management System (ISO 13485)",
  },
  [ArticleId.RiskManagementSystem]: {
    id: "7BWraNtpg09EzHggH4UJ3h",
    title: "Risk Management (ISO 14971)",
  },
};

export default ArticlesConfig;
export type ArticlesConfig = Record<ArticleId, { id: string; title: string }>;
