import { Skeleton } from "@mui/material";

export const DocumentViewerSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <Skeleton
        key={"toolbar-skel"}
        variant="rounded"
        width="100%"
        height={37}
        className="mb-2"
      />
      <Skeleton
        key={"title-skel"}
        variant="rounded"
        width="20%"
        height={55}
        className="mb-2"
      />
      <div className="flex flex-col gap-y-6">
        {Array.from({ length: 5 }).map((_, i) => (
          <div key={i} className="flex flex-col gap-y-3">
            <Skeleton
              key={i + "_1"}
              variant="rounded"
              width="34%"
              height={40}
            />
            <Skeleton
              key={i + "_2"}
              variant="rounded"
              width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
              height={24}
            />
            <Skeleton
              key={i + "_3"}
              variant="rounded"
              width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
              height={24}
            />
            <Skeleton
              key={i + "_4"}
              variant="rounded"
              width="30%"
              height={24}
            />
            <Skeleton
              key={i + "_5"}
              variant="rounded"
              width="10%"
              height={24}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
