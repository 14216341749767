import { date, InferType, object, string } from "yup";

import { roadmapQuestionnaireSchema } from "./roadmap-questionnaire";

export const deviceSchema = object({
  id: string().required(),
  name: string().required(),
  // TODO make required
  description: string().default(""),
  roadmapQuestionnaire: roadmapQuestionnaireSchema.required(),
  createdBy: string().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
});

export interface Device extends InferType<typeof deviceSchema> {}
