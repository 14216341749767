import { User } from "@auth0/auth0-react";
import { usePatchDocumentVersionMutation } from "@hooks";
import { Device, Document, DocumentVersion, TEMPLATE_TYPE } from "@models";
import { useEffect, useState } from "react";
import {
  generateDocument,
  generateStaticDocument,
  getDocumentAnswerMap,
  isDocumentCompleted,
  isStaticallyGeneratedDocument,
} from "src/utils";

export const useGenerateDocument = ({
  document,
  user,
  selectedDocVersion,
  templateId,
  selectedDoc,
  documents,
  deviceId,
  device,
}: {
  document: Document | undefined | null;
  user: User | undefined;
  selectedDocVersion: DocumentVersion | undefined;
  templateId: TEMPLATE_TYPE;
  deviceId: string;
  selectedDoc: Document | undefined | null;
  documents: Document[] | undefined;
  device: Device | undefined;
}) => {
  // If the template id changes reset the selected document version otherwise the old selected document version will be used for the new template
  useEffect(() => {
    setGeneratedDoc(undefined);
  }, [templateId]);

  const patchDocumentMutation = usePatchDocumentVersionMutation();

  const [generatedDoc, setGeneratedDoc] = useState<
    { docVersionId: string; data: string } | undefined
  >(undefined);

  const [isGeneratingDoc, setIsGeneratingDoc] = useState<string | null>(null);

  const docIsStatic = isStaticallyGeneratedDocument(templateId);

  const handleGenerateDocument = async (
    user: User,
    device: Device,
    documents: Document[],
    document: Document,
    selectedDocVersion: DocumentVersion
  ) => {
    setIsGeneratingDoc(selectedDocVersion.id);

    const generatedDoc = docIsStatic
      ? await generateStaticDocument(
          user,
          device,
          templateId,
          document,
          selectedDocVersion,
          documents
        )
      : await generateDocument(
          user,
          device,
          document,
          selectedDocVersion,
          documents,
          templateId,
          getDocumentAnswerMap(selectedDocVersion)
        );

    setGeneratedDoc({
      docVersionId: selectedDocVersion.id,
      data: generatedDoc,
    });
    setIsGeneratingDoc(null);
  };

  /* Generate the document if the doc version is complete and the user, device, selectedDocVersion and document are available */
  useEffect(() => {
    if (
      user &&
      device &&
      selectedDoc &&
      selectedDocVersion &&
      isDocumentCompleted(selectedDoc, selectedDocVersion) &&
      document &&
      documents &&
      generatedDoc?.docVersionId !== selectedDocVersion.id &&
      isGeneratingDoc !== selectedDocVersion.id
    ) {
      handleGenerateDocument(
        user,
        device,
        documents,
        document,
        selectedDocVersion
      );
    }
  }, [
    user,
    device,
    selectedDocVersion,
    document,
    documents,
    generatedDoc,
    isGeneratingDoc,
  ]);

  /**
   * Set a static document's readyForApproval to true if its the first time the document is opened
   */
  useEffect(() => {
    if (
      document &&
      docIsStatic &&
      document.versions[0]?.readyForApproval !== true
    ) {
      patchDocumentMutation.mutate({
        deviceId: deviceId,
        docId: document.id,
        docVersionId: document.versions[0]?.id,
        data: { readyForApproval: true },
      });
    }
  }, [document, docIsStatic]);

  return { generatedDoc, isGeneratingDoc, setGeneratedDoc };
};
