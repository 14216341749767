import {
  BillingInterval,
  Device,
  Document,
  DocumentAnswer,
  DocumentAnswerSchema,
  DocumentSchema,
  DocumentVersion,
  DocumentVersionApprover,
  DocumentVersionSchema,
  Plan,
  RoadmapTasks,
  TEMPLATE_TYPE,
  User,
  deviceSchema,
} from "@models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  ASSISTANT_CONFIG,
  ROUTES,
  getApproversQueryKey,
  getCreateDocumentMutationKey,
  getDevicesQueryKey,
  getDocumentQueryKey,
  getDocumentsQueryKey,
  getPatchApproverStatusMutationKey,
  getTasksQueryKey,
  getUserQueryKey,
  getUserTodosQueryKey,
} from "../config";
import { Element } from "../pages/MultiStepForm";
import {
  checkout,
  createDocument,
  createDocumentVersion,
  deleteDocumentApprover,
  deleteSubscription,
  patchApproverStatus,
  patchDocumentVersion,
  patchRoadmapQuestionnaire,
  patchRoadmapTasks,
  postBillingSession,
  postDevice,
  postDocumentAnswer,
  postDocumentApprover,
  postUserInvite,
  updateSubscription,
  updateUser,
  uploadDocument,
} from "../services";
import { rootStore } from "../stores";
import { NewDevice, PatchDocumentVersionData, TemplateElement } from "../types";

const { documentStore } = rootStore;

export const useSaveSuggestion = (element: TemplateElement) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      suggestion,

      documentVersion,
    }: {
      suggestion: string;
      documentVersion: DocumentVersion;
      device: Device;
      docId: string;
    }) =>
      documentStore.saveSuggestion(
        documentVersion.documentId,
        documentVersion.id,
        element.id,
        suggestion
      ),
    onSuccess: (_, { device, docId }) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(device.id, docId),
      });
    },
  });
};

export const usePatchDocumentVersionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      docId,
      docVersionId,
      data,
    }: {
      docId: string;
      docVersionId: string;
      deviceId: string;
      data: PatchDocumentVersionData;
    }) => {
      const { data: newDoc } = await patchDocumentVersion(
        docId,
        docVersionId,
        data
      );
      return DocumentVersionSchema.validateSync(newDoc);
    },
    onMutate: async ({ docId, docVersionId, deviceId, data }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );

      const previousDocs: Document[] | undefined = queryClient.getQueryData(
        getDocumentsQueryKey(deviceId)
      );

      if (!previousDoc || !previousDocs) {
        return;
      }

      const documentVersion = previousDoc.versions.find(
        (v) => v.id === docVersionId
      );

      if (!documentVersion) {
        return;
      }

      const updatedVersion = {
        ...documentVersion,
        ...data,
      };

      const updatedDoc = {
        ...previousDoc,
        versions: [
          updatedVersion,
          ...previousDoc.versions.filter((v) => v.id !== docVersionId),
        ],
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      // Update the version status in the documents list local cache
      queryClient.setQueryData(getDocumentsQueryKey(deviceId), () => [
        ...previousDocs.filter((d) => d.id !== docId),
        updatedDoc,
      ]);

      return { previousDoc: previousDoc, previousDocs: previousDocs };
    },
    onError: (_, __, context) => {
      if (context?.previousDoc) {
        queryClient.setQueryData(
          getDocumentQueryKey(
            context.previousDoc.deviceId,
            context.previousDoc.id
          ),
          context.previousDoc
        );

        queryClient.setQueryData(
          getDocumentsQueryKey(context.previousDoc.deviceId),
          context.previousDocs
        );
      }
    },
    onSuccess: (_, { deviceId, docId }) =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: getUserTodosQueryKey(deviceId),
        }),
        queryClient.invalidateQueries({
          queryKey: getDocumentsQueryKey(deviceId),
        }),
        queryClient.invalidateQueries({
          queryKey: getDocumentQueryKey(deviceId, docId),
        }),
      ]),
  });
};

export const useCreateDeviceMutation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ device }: { device: NewDevice }) => {
      const { data } = await postDevice({
        name: device.name,
        description: device.description,
      });

      const questionnaire = await patchRoadmapQuestionnaire(
        data.id,
        device.questionnaire
      );
      data.roadmapQuestionnaire = questionnaire.data;

      return await deviceSchema.validate(data);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(getDevicesQueryKey(), (old: Device[]) => [
        ...old,
        data,
      ]);
      navigate(ROUTES.DEVICE_OVERVIEW.replace(":deviceId", data.id));
    },
  });
};

export const useUploadDocumentMutation = ({ deviceId }: { deviceId: string }) =>
  useMutation({
    mutationFn: async ({
      templateId,
      file,
    }: {
      templateId: TEMPLATE_TYPE;
      file: File;
    }) =>
      await uploadDocument({
        templateId,
        deviceId,
        file,
      }),
  });

export const useTaskFinishedMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      completed,
      tasks,
      templateId,
      deviceId,
    }: {
      completed: boolean;
      tasks: RoadmapTasks;
      templateId: TEMPLATE_TYPE;
      deviceId: string;
    }) => {
      return patchRoadmapTasks(deviceId, {
        ...tasks.tasks,
        [templateId]: completed,
      });
    },
    onMutate: ({ completed, deviceId, templateId }) => {
      queryClient.setQueryData(
        getTasksQueryKey(deviceId),
        (old: RoadmapTasks) => ({
          ...old,
          tasks: { ...old?.tasks, [templateId]: completed },
        })
      );
    },
    onSuccess: (_, { deviceId }) => {
      queryClient.invalidateQueries({ queryKey: getTasksQueryKey(deviceId) });
    },
  });
};

export const useCreateDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getCreateDocumentMutationKey(),
    mutationFn: async ({
      templateId,
      deviceId,
    }: {
      templateId: TEMPLATE_TYPE;
      deviceId: string;
    }) => {
      const config = ASSISTANT_CONFIG[templateId];
      if (!config) {
        throw new Error("Invalid templateId");
      }
      const { data } = await createDocument(deviceId, templateId);
      return DocumentSchema.validate(data);
    },
    onSuccess: async (data, { deviceId }) => {
      // Update the documents list local cache
      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]): Document[] => {
          return [...old, data];
        }
      );

      queryClient.setQueryData(getDocumentQueryKey(deviceId, data.id), data);

      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, data.id),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });
    },
  });
};

export const useSaveAnswerMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      answer,
      step,
      documentVersion,
    }: {
      docId: string;
      deviceId: string;
      answers: Record<string, string | null>;
      answer: string;
      step: Element;
      documentVersion: DocumentVersion;
    }) => {
      const updatedDocVersion = await postDocumentAnswer(
        documentVersion.documentId,
        documentVersion.id,
        {
          answer,
          element: step.id,
        }
      );
      return DocumentAnswerSchema.validateSync(updatedDocVersion.data);
    },
    onMutate: async ({
      docId,
      deviceId,
      answers,
      answer,
      step,
      documentVersion,
    }) => {
      const previousDoc: Document | undefined = queryClient.getQueryData(
        getDocumentQueryKey(deviceId, docId)
      );
      const previousAnswer = documentVersion.answers.find(
        (a) => a.element === step.id
      );

      const newAnswer = {
        documentVersionId: documentVersion.id,
        // Todo use id if the answer is already saved
        id: previousAnswer?.id || "temp",
        element: step.id,
        value: answers[step.id],
        answer: answer,
        createdBy: previousAnswer?.createdBy || "temp",
        createdAt: previousAnswer?.createdAt || new Date(),
        updatedAt: new Date(),
      };

      const updatedDoc = {
        ...previousDoc,
        versions: previousDoc?.versions.map((version) => {
          if (version.id === documentVersion.id) {
            return {
              ...version,
              answers: [
                ...version.answers.filter(
                  (a: DocumentAnswer) => a.element !== step.id
                ),
                newAnswer,
              ],
            };
          } else {
            return version;
          }
        }),
      };

      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        updatedDoc
      );

      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => [...old.filter((d) => d.id !== docId), updatedDoc]
      );
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(variables.deviceId, variables.docId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(variables.deviceId),
      });
      // Optional return the invalidation to wait until both queries are invalidated
      // https://tkdodo.eu/blog/mastering-mutations-in-react-query#awaited-promises
    },
  });
};

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, user }: { id: string; user: Partial<User> }) =>
      updateUser(id, user),
    onMutate: ({ user }) => {
      queryClient.setQueryData(getUserQueryKey(), (old: User) => ({
        ...old,
        ...user,
      }));
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
    },
  });
};

export const useInviteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      inviteeEmail,
      inviteeFirstName,
      inviteeLastName,
    }: {
      inviteeEmail: string;
      inviteeFirstName: string;
      inviteeLastName: string;
    }) => postUserInvite({ inviteeEmail, inviteeFirstName, inviteeLastName }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getUserQueryKey(),
      });
    },
  });
};

export const patchApproverStatusMutation = ({
  documentId,
  versionId,
  deviceId,
}: {
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: getPatchApproverStatusMutationKey(),
    mutationFn: (data: {
      approverId: string;
      status: "USER_INVITE_SENT" | "AWAITING_SIGNATURE" | "APPROVED";
    }) =>
      patchApproverStatus({
        documentId,
        versionId,
        data,
      }),
    onMutate: async (data) => {
      const previousData: DocumentVersionApprover[] | undefined =
        queryClient.getQueryData(getApproversQueryKey(documentId, versionId));

      if (previousData) {
        const nextData = previousData.map((approver) => {
          if (approver.id === data.approverId) {
            return { ...approver, approvalStatus: data.status };
          } else {
            return approver;
          }
        });

        // We update the local cache to reflect the new status
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          nextData
        );

        return { previousData };
      }
    },
    onError: (_, __, context) => {
      // If the mutation fails, we revert the local cache to the previous state
      if (context?.previousData) {
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          context.previousData
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      });
      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
    },
  });
};

export const postApproverMutation = ({
  documentId,
  versionId,
  deviceId,
}: {
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { userId: string }) =>
      postDocumentApprover({
        documentId,
        versionId,
        data,
      }),
    onMutate: async (data) => {
      const previousData: DocumentVersionApprover[] | undefined =
        queryClient.getQueryData(getApproversQueryKey(documentId, versionId));

      if (previousData) {
        const nextData = [
          ...previousData,
          {
            id: "temp",
            user: {
              id: data.userId,
            },
            approvalStatus: "AWAITING_SIGNATURE",
          },
        ];

        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          nextData
        );

        return { previousData };
      }
    },
    onError: (_, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          getApproversQueryKey(documentId, versionId),
          context.previousData
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      }),
        queryClient.invalidateQueries({
          queryKey: getDocumentsQueryKey(deviceId),
        });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, documentId),
      });
    },
  });
};

export const deleteApproverMutation = ({
  documentId,
  versionId,
  deviceId,
}: {
  documentId: string;
  versionId: string;
  deviceId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { approverId: string }) =>
      deleteDocumentApprover(documentId, versionId, data.approverId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getApproversQueryKey(documentId, versionId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, documentId),
      });
    },
  });
};

export const useCreateDocumentVersionMutation = (deviceId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ docId, file }: { docId: string; file?: File }) => {
      const { data } = await createDocumentVersion(docId, file);
      return DocumentVersionSchema.validateSync(data);
    },
    onSuccess: (data, { docId }) => {
      queryClient.invalidateQueries({
        queryKey: getDocumentQueryKey(deviceId, docId),
      });
      queryClient.invalidateQueries({
        queryKey: getDocumentsQueryKey(deviceId),
      });

      queryClient.invalidateQueries({
        queryKey: getUserTodosQueryKey(deviceId),
      });
      queryClient.setQueryData(
        getDocumentQueryKey(deviceId, docId),
        (old: Document) => {
          old.versions.push(data);
          return {
            ...old,
            versions: orderBy(old.versions, "createdAt", "desc"),
          };
        }
      );
      queryClient.setQueryData(
        getDocumentsQueryKey(deviceId),
        (old: Document[]) => {
          return old.map((doc) => {
            if (doc.id === docId) {
              return {
                ...doc,
                versions: orderBy([data, ...doc.versions], "createdAt", "desc"),
              };
            } else {
              return doc;
            }
          });
        }
      );
    },
  });
};

export const useCheckoutMutation = () => {
  return useMutation({
    mutationFn: async ({
      plan,
      amount,
      cycle,
    }: {
      plan: Plan.PREMIUM | Plan.PREMIUM_PLUS;
      amount: number;
      cycle: BillingInterval;
    }) => {
      const { data } = await checkout(plan, amount, cycle);
      return data as string;
    },
  });
};

export const useCreateBillingSessionMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await postBillingSession();
      return data as string;
    },
  });
};

export const useDeleteSubscriptionMutation = () => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await deleteSubscription();
      return data as string;
    },
  });
};

export const useUpdateSubscriptionMutation = () => {
  return useMutation({
    mutationFn: async ({
      plan,
      amount,
      cycle,
    }: {
      plan: Plan;
      amount: number;
      cycle: BillingInterval;
    }) => {
      const { data } = await updateSubscription(plan, amount, cycle);
      return data as string;
    },
  });
};
