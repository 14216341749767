import { useTheme } from "@mui/material";
import { TreeItem, TreeItemProps } from "@mui/x-tree-view/TreeItem";
import { TemplateAssistant } from "../../../types";

export type TemplateTreeItemProps = Partial<TreeItemProps> & {
  template: TemplateAssistant;
  blueIcon?: boolean;
};

export const TemplateTreeItem = ({
  template,
  className,
  disabled = false,
  slots,
  blueIcon = false,
}: TemplateTreeItemProps) => {
  const theme = useTheme();

  return (
    <TreeItem
      className={className}
      key={`${template.id}-template-tree-item`}
      itemId={template.id}
      label={template.docName}
      disabled={disabled}
      slots={slots}
      sx={
        blueIcon
          ? {
              "& .MuiTreeItem-iconContainer": {
                color: theme.palette.primary.main,
              },
            }
          : {}
      }
    />
  );
};
