import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/confetti.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
  duration: 2000,
};

const ConfettiAnimation = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 right-0 z-[1202] ${isVisible ? "visible" : "hidden"}`}
    >
      {/** need this z-index because navbar z-index is 1201 and we want the confetti to go over it */}
      <Lottie options={defaultOptions} width="100vw" height="100vh" />
    </div>
  );
};

export default ConfettiAnimation;
