export type RoadmapStageId =
  | "planning"
  | "design-and-development"
  | "testing-and-validation"
  | "regulatory-preparation"
  | "submission";

export type RoadmapStageConfig = {
  title: string;
  id: RoadmapStageId;
  disabled?: boolean;
};

export const ROADMAP_STAGES: RoadmapStageConfig[] = [
  {
    title: "Planning",
    id: "planning",
    disabled: false,
  },
  {
    title: "Design and Development",
    id: "design-and-development",
    disabled: false,
  },
  {
    title: "Testing and Validation",
    id: "testing-and-validation",
    disabled: false,
  },
  {
    title: "Regulatory Preparation",
    id: "regulatory-preparation",
    disabled: false,
  },
  {
    title: "Submission",
    id: "submission",
    disabled: false,
  },
];
