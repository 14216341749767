import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { getDocumentQueryKey } from "../../../../../config";
import { patchApproverStatusMutation } from "../../../../../hooks";
import {
  DocumentVersion,
  DocumentVersionApprover,
  DocumentVersionApproverStatus,
} from "../../../../../stores/models";
import { getApproverStatusText, userStatusDictionary } from "../utils";
import ApproverActions from "./ApproverActions";
import SectionContainer from "./SectionContainer";
import ApproverStatusChip from "./StatusChip";

export const buttonsDictionary: Record<
  DocumentVersionApproverStatus,
  {
    status: DocumentVersionApproverStatus;
    color:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning";
    text: string;
  }
> = {
  [DocumentVersionApproverStatus.APPROVED]: {
    status: DocumentVersionApproverStatus.AWAITING_SIGNATURE,
    color: "error",
    text: "Unapprove",
  },
  [DocumentVersionApproverStatus.AWAITING_SIGNATURE]: {
    text: "Approve",
    status: DocumentVersionApproverStatus.APPROVED,
    color: "success",
  },
  [DocumentVersionApproverStatus.USER_INVITE_SENT]: {
    text: "Unapprove",
    status: DocumentVersionApproverStatus.APPROVED,
    color: "success",
  },
};

export const ApproverContent = ({
  selectedDocVersion,
  revisionSummary,
  approvers,
  approver,
}: {
  selectedDocVersion: DocumentVersion;
  approvers: DocumentVersionApprover[];
  revisionSummary: string;
  approver: DocumentVersionApprover;
}) => {
  const queryClient = useQueryClient();
  const { deviceId = "" } = useParams();
  const patchApproverStatus = patchApproverStatusMutation({
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
    deviceId,
  });

  const approversSortedByCreationDate = orderBy(approvers, "createdAt", "asc");

  const approvalStatus =
    approver.approvalStatus || DocumentVersionApproverStatus.AWAITING_SIGNATURE;

  const handleApprove = async () => {
    await patchApproverStatus.mutateAsync({
      status: buttonsDictionary[approvalStatus].status,
      approverId: approver.id,
    });

    queryClient.invalidateQueries({
      queryKey: getDocumentQueryKey(deviceId, selectedDocVersion.documentId),
    });
  };

  return (
    <div className="flex flex-col gap-2 pt-2 overflow-auto">
      <div className="flex flex-col gap-4">
        <ApproverActions handleApprove={handleApprove} approver={approver} />
        <SectionContainer
          title="Assignees"
          content={
            <div className="flex gap-3 w-full">
              {approversSortedByCreationDate.map(
                (approver) =>
                  approver && (
                    <ApproverStatusChip
                      person={approver.user}
                      key={approver.id}
                      color={
                        userStatusDictionary[approver.approvalStatus].variant
                      }
                      text={getApproverStatusText(approver)}
                    />
                  )
              )}
            </div>
          }
        />
        <SectionContainer
          title="Revision Summary"
          content={<Typography fontSize="body2">{revisionSummary}</Typography>}
        />
      </div>
    </div>
  );
};

export default ApproverContent;
