import { Chip } from "@mui/material";

const CustomChip = ({
  label,
  color,
  size,
  className,
}: {
  label: string;
  color: string;
  size: "small" | "medium" | "tiny";
  className?: string;
}) => (
  <Chip
    className={className}
    label={label}
    size={size}
    sx={{
      backgroundColor: `${color}20`,
      border: `1px solid ${color}`,
      color: color,
    }}
  />
);

export default CustomChip;
