import { SetURLSearchParams } from "react-router-dom";

/**
 * Update the search params of the current url with the provided params without removing any existing params
 * @param setSearchParams the function of react router to set the urls search params provided by useSearchParams
 * @param params the urls query params to set or update
 */
export const updateSearchParams = (
  setSearchParams: SetURLSearchParams,
  params: { [i: string]: string }
) => {
  setSearchParams((searchParams) => {
    Object.entries(params).forEach(([k, v]) => {
      searchParams.set(k, v);
    });
    return searchParams;
  });
};
