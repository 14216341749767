import { config } from "../config/assistant";
import { TEMPLATE_TYPE } from "../stores/models";
import { DocumentDataKey, TableElement, TemplateElement } from "../types";

// TODO should probably be replace by parseMarkdownTable
export function markdownTableToObject(
  markdown: string
): Record<string, string>[] {
  // Split the input by lines
  const lines = markdown.trim().split("\n") || [];

  if (!lines[0]) {
    return [];
  }

  // Extract headers, ignoring empty strings
  const headers = (lines[0] || "")
    .split("|")
    .map((header) => header.trim())
    .filter((header) => header);

  // Extract rows, ignoring cells that are empty strings
  const rows = lines.slice(2).map((line) =>
    line
      .split("|")
      .map((cell) => cell.trim())
      .filter((cell) => cell)
  );

  // Create objects for each row
  const objects = rows.map((row) => {
    let obj: Record<string, string> = {};
    row.forEach((cell, index) => {
      obj[headers[index]] = cell;
    });
    return obj;
  });

  return objects;
}

// interface SoupRecord {
//   ["SOUP ID"]: string;
//   ["Software Item"]: string;
//   ["SOUP Name"]: string;
//   ["Version"]: string;
//   ["Manufacturer"]: string;
//   ["Description"]: string;
//   ["Risk Level"]: string;
//   ["License"]: string;
//   ["Requirements"]?: string;
// }

// Function to parse Markdown table to array of objects
export function parseMarkdownTable(table: string): Record<string, string>[] {
  const lines = table
    .split("\n")
    .filter((line) => !line.startsWith("| ---"))
    // Remove empty lines
    .filter((line) => !!line.trim());

  if (lines.length === 0) {
    return [];
  }

  const headers = lines[0]
    .split("|")
    .map((header) => header.trim())
    .filter((header) => header);

  return lines.slice(1).map((line) => {
    const values = line
      .split("|")
      // We need to trim the first and last values because they are empty strings
      .slice(1, -1)
      .map((value) => value.trim());

    const record: any = {};
    headers.forEach((header, index) => {
      record[header] = values[index] || "";
    });
    return record;
  });
}

// We only want to include records that have a valid id
function filerFaultyRecords(
  record: Record<string, string>,
  indexAttribute: string
) {
  const regex = /^[A-Z]{2}\d+$/;
  const isValidId = regex.test(record[indexAttribute]);
  return isValidId;
}

// Function to merge arrays of SoupRecords
export function mergeTables(
  table1: Record<string, string>[],
  table2: Record<string, string>[],
  indexAttribute: string
): Record<string, string>[] {
  const recordMap: { [id: string]: Record<string, string> } = {};

  // Add all records from table1
  table1
    .filter((r) => filerFaultyRecords(r, indexAttribute))
    .forEach((record) => {
      recordMap[record[indexAttribute]] = {
        ...record,
        ["Requirements"]: record["Requirements"] || "N/A",
      };
    });

  // Add records from table2 if they don't exist in table1
  table2
    .filter((r) => filerFaultyRecords(r, indexAttribute))
    .forEach((record) => {
      if (!recordMap[record[indexAttribute]]) {
        recordMap[record[indexAttribute]] = {
          ...record,
          ["Requirements"]: "N/A",
        };
      }
    });

  return Object.values(recordMap);
}

// Function to convert array of objects to Markdown table
export function toMarkdownTable(records: Record<string, string>[]): string {
  const headers = Object.keys(records[0] || {});
  let table = `| ${headers.join(" | ")} |\n`;
  table += `| ${headers.map(() => "---").join(" | ")} |\n`;
  records.forEach((record) => {
    table += `| ${headers.map((header) => record[header]).join(" | ")} |\n`;
  });
  return table;
}

export const getTableColumns = (
  document: TEMPLATE_TYPE,
  dataKey: DocumentDataKey
) =>
  (
    (config[document].elements.find((e) => e.id === dataKey) as TemplateElement)
      .element as TableElement
  ).options.columns.map((c) => c.name);
