import { DocumentVersion, User } from "../../../../../stores/models";

export const enum OutstandingAction {
  "SELECT_APPROVERS" = "SELECT_APPROVERS",
  "APPROVAL_PENDING" = "APPROVAL_PENDING",
  "ALL_DONE" = "ALL_DONE",
}

export type TOutstandingAction = keyof typeof OutstandingAction;

export interface HandleOutstandingActionCommonProps {
  outstandingAction: TOutstandingAction | null;
  setDocInfoDrawerOpen: (value: boolean) => void;
  setOutstandingAction: (value: TOutstandingAction) => void;
}

export interface HandleOutstandingActionProps
  extends HandleOutstandingActionCommonProps {
  user: User;
  selectedDocVersion: DocumentVersion;
}

export type DefineOutstandingActionProps =
  HandleOutstandingActionCommonProps & {
    condition: boolean;
    action: TOutstandingAction;
  };
