import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../config";

export default function Error() {
  const navigate = useNavigate();

  return (
    <Container
      maxWidth="md"
      className="items-center justify-center flex flex-auto flex-col h-full min-h-[300px]"
    >
      <Typography variant="h1" className="mb-2">
        Looks like an error has occurred.
      </Typography>
      <Typography variant="body2">
        Email support@formly.ai if assistance required.
      </Typography>
      <Button
        className="mt-6"
        variant="contained"
        onClick={() => navigate(ROUTES.DEVICE_MANAGEMENT)}
      >
        Bring Me Home
      </Button>
    </Container>
  );
}
