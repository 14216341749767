import { ContentCopy, Edit, MoreVert } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { DocStatus } from "@types";
import { useState } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import DefaultButton from "src/components/DefaultButton/DefaultButton";
import { ASSISTANT_CONFIG } from "src/config";
import {
  getDocVersionStatus,
  hasRevisionOneOreMoreApprovals,
  isDocEditable,
  isDocVersionAuthor,
  isDocVersionDraft,
  isStaticallyGeneratedDocument,
  UPGRADE_PLAN_TOOLTIP,
} from "../../../../utils";
import { handleCopy } from "../../utils/handlers";
import { DocViewerTopBarProps } from "./DocViewerTopBar.types";
import DocRevisionDropDown from "./selects/DocRevisionDropDown";
import DocVersionDropdown from "./selects/DocVersionDropdown";

export const DocViewerTopBar = ({
  docs,
  docVersions,
  generatedDoc,
  hasOutstandingAction,
  isCreatingNewInstance,
  isEditingRawDocument,
  loadingNewDocVersion,
  onCancelEditRawDocument,
  onCreateDocInstance,
  onDocInfoButtonClick,
  onEditClick,
  onEditRawDocument,
  onSaveRawDocument,
  onSelectDocInstance,
  onSelectDocVersion,
  selectedDoc,
  selectedVersion,
  templateId,
  user,
  isDocEnabled,
}: DocViewerTopBarProps) => {
  const docConfig = ASSISTANT_CONFIG[templateId];
  const canHaveMultipleInstances = docConfig.docType === "RCD";

  const [loadingClipboard, setLoadingClipboard] = useState(false);
  const [_, setCopySuccess] = useState(false);

  const docIsEditable = isDocEditable(selectedDoc);
  const docVersionStatus = getDocVersionStatus(selectedVersion, docVersions);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmRawEditModalOpen, setConfirmRawEditModalOpen] = useState(false);
  const [editConfirmationOpen, setEditConfirmationOpen] = useState(false);
  const moreOptionsOpen = Boolean(anchorEl);
  const handleClickMoreOptions = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMoreOptions = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setEditConfirmationOpen(true);
  };

  const handleEditConfirm = () => {
    setEditConfirmationOpen(false);
    onEditClick(selectedDoc);
  };

  const handleEditCancel = () => {
    setEditConfirmationOpen(false);
  };

  const getEditButton = (docVersionStatus: DocStatus) => {
    const isLiveOrArchived = ["LIVE", "ARCHIVED"].includes(docVersionStatus);
    const isDraft = docVersionStatus === "DRAFT";
    const docHasDraft = selectedDoc.versions
      .map((v) => getDocVersionStatus(v, docVersions))
      .some((s) => s === "DRAFT");
    const isStaticallyGenerated = isStaticallyGeneratedDocument(templateId);

    if (isStaticallyGenerated && isDraft) {
      return (
        <DefaultButton
          isDisabled={!isDocEnabled}
          onClick={handleEditClick}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
          text="Reset draft"
        />
      );
    }

    if (isLiveOrArchived && docHasDraft) {
      return (
        <DefaultButton
          isDisabled={!isDocEnabled}
          onClick={() => onSelectDocVersion(selectedDoc.versions[0])}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
          text="Edit draft"
        />
      );
    }

    if (isLiveOrArchived && !docHasDraft) {
      return (
        <DefaultButton
          isDisabled={!isDocEnabled || loadingNewDocVersion}
          onClick={handleEditClick}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
          text="New revision"
        />
      );
    }

    if (isDraft) {
      return (
        <DefaultButton
          isDisabled={!isDocEnabled || loadingNewDocVersion}
          id="basic-button"
          onClick={handleEditClick}
          tooltipContent={UPGRADE_PLAN_TOOLTIP}
          hideTooltip={isDocEnabled}
        >
          {loadingNewDocVersion ? (
            <CircularProgress size={"1rem"} color="inherit" />
          ) : (
            <Edit fontSize="small" />
          )}
        </DefaultButton>
      );
    }
    return null;
  };

  return (
    <div className={`flex items-center justify-between rounded-md`}>
      <div>
        {!selectedVersion ? (
          <Skeleton width={200} height={42} className="transform-none" />
        ) : (
          <div className="flex">
            {canHaveMultipleInstances && docs && selectedDoc && (
              <DocRevisionDropDown
                docVersions={docVersions}
                selectedDoc={selectedDoc}
                selectedVersion={selectedVersion}
                isDocEnabled={isDocEnabled}
                isMultiInstanceDoc={canHaveMultipleInstances}
                {...(canHaveMultipleInstances && {
                  docs,
                  onCreateDocInstance,
                  onSelectDocInstance,
                  isCreatingNewInstance,
                })}
                showStatus={!["TMP", "EXT"].includes(docConfig.docType)}
              />
            )}
            <DocVersionDropdown
              isMultiInstanceDoc={canHaveMultipleInstances}
              showStatus={!["TMP", "EXT"].includes(docConfig.docType)}
              docVersions={docVersions}
              selectedVersion={selectedVersion}
              onSelectDocVersion={onSelectDocVersion}
              isDocEnabled={isDocEnabled}
            />
          </div>
        )}
      </div>
      {isEditingRawDocument ? (
        <>
          <div className="flex items-center gap-2">
            <Button
              variant="outlined"
              color="inherit"
              onClick={onCancelEditRawDocument}
            >
              Abort
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setConfirmRawEditModalOpen(true)}
            >
              Save
            </Button>
          </div>
          <ConfirmationModal
            key="confirm-raw-edit"
            title="Are you sure you want to save the edited document?"
            confirmText="Save"
            content={
              <>
                If you save raw edited document, Formly is
                <ul>
                  <li>not able to track the changes</li>
                  <li>will not be able to use them for suggestions</li>
                  <li>
                    not be able to tell you which other documents are influenced
                    by the changes you made and therefore might need revision.
                  </li>
                </ul>
              </>
            }
            onConfirm={() => {
              onSaveRawDocument();
              setConfirmRawEditModalOpen(false);
            }}
            open={confirmRawEditModalOpen}
            onAbort={() => setConfirmRawEditModalOpen(false)}
          />
        </>
      ) : (
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            {selectedDoc &&
              docIsEditable &&
              docVersionStatus &&
              getEditButton(docVersionStatus)}
            <ConfirmationModal
              key="confirm-edit"
              title="Edit document"
              confirmText="Confirm"
              content="Are you sure you want to edit this document? This will create a new draft revision or replace the current draft."
              onAbort={handleEditCancel}
              onConfirm={handleEditConfirm}
              open={editConfirmationOpen}
            />

            {/* 
              add snackbar here when copied 
              title={copySuccess && "Copied!"} open={copySuccess}
            */}
            <DefaultButton
              onClick={() =>
                handleCopy(generatedDoc, setLoadingClipboard, setCopySuccess)
              }
              isDisabled={
                !isDocEnabled || loadingClipboard || isEditingRawDocument
              }
              tooltipContent={UPGRADE_PLAN_TOOLTIP}
              hideTooltip={isDocEnabled}
            >
              <ContentCopy fontSize="small" />
            </DefaultButton>
            <div>
              <DefaultButton
                isDisabled={!isDocEnabled || isEditingRawDocument}
                ariacontrols={moreOptionsOpen ? "basic-menu" : undefined}
                ariahaspopup={true}
                ariaexpanded={moreOptionsOpen ? true : undefined}
                id="basic-button"
                onClickEvent={handleClickMoreOptions}
                tooltipContent={UPGRADE_PLAN_TOOLTIP}
                hideTooltip={isDocEnabled}
              >
                <MoreVert />
              </DefaultButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={moreOptionsOpen}
                onClose={handleCloseMoreOptions}
                onClick={handleCloseMoreOptions}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                disablePortal
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Typography color="error" className="px-4">
                  Danger Zone
                </Typography>
                <Divider className="my-2" />
                <MenuItem
                  disabled={
                    !isDocVersionDraft(selectedVersion, selectedDoc.versions) ||
                    hasRevisionOneOreMoreApprovals(selectedVersion) ||
                    !isDocVersionAuthor(selectedVersion, user)
                  }
                  onClick={() => {
                    handleCloseMoreOptions();
                    onEditRawDocument();
                  }}
                >
                  Edit raw document
                </MenuItem>
              </Menu>
            </div>
          </div>
          <span className="relative inline-flex">
            <DefaultButton
              isDisabled={!isDocEnabled || isEditingRawDocument}
              onClick={onDocInfoButtonClick}
              tooltipContent={
                isDocEnabled ? (
                  <>
                    Toggle approval <kbd>A</kbd>
                  </>
                ) : (
                  UPGRADE_PLAN_TOOLTIP
                )
              }
              text="Approval"
              size="medium"
              variant="outlined"
              hasOutstandingAction={isDocEnabled && hasOutstandingAction}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default DocViewerTopBar;
