import { Card, styled } from "@mui/material";

export const CardCustom = styled(Card)({
  "&.MuiPaper-root": {
    boxShadow: "none",
  },

  "& .MuiCardContent-root": {
    border: "1px solid rgba(0,0,0,0.14)",
    padding: "12px",
  },
});
