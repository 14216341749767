import { LinearProgress } from "@mui/material";

type Props = {};
export const Loading = ({}: Props) => {
  return (
    <div className="flex flex-1 h-full items-center justify-center ">
      <LinearProgress color="primary" className="w-96" />
    </div>
  );
};
