import { useGetDevices, useGetUser } from "@hooks";
import { AddRounded } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getSubscription } from "@utils";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DashboardSkeleton,
  DeviceCreationModal,
  DeviceList,
} from "../components";
import { ROUTES } from "../config";
import { useStore } from "../stores";

function Dashboard() {
  const { deviceStore } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useGetUser();
  const { data: devices, isLoading: isLoadingDevices } = useGetDevices();

  const [newDeviceModalOpen, setNewDeviceModalOpen] = useState(false);

  const subscription = user ? getSubscription(user) : null;
  // Prefetch roadmap tasks for each displayed device
  useQuery({
    queryKey: ["tasks"],
    queryFn: () =>
      devices &&
      Promise.all(
        devices.map(async (device) => deviceStore.fetchRoadmapTasks(device.id))
      ),
    enabled: !!devices,
  });

  const shouldNavigateToRoadmap =
    devices?.length === 1 && !location.state?.previous;

  useEffect(() => {
    if (shouldNavigateToRoadmap) {
      navigate(ROUTES.DEVICE_OVERVIEW.replace(":deviceId", devices[0].id));
    }
  }, [devices, location]);

  if (
    shouldNavigateToRoadmap ||
    (isLoadingDevices && !devices) ||
    !user ||
    !devices ||
    !subscription
  ) {
    return <DashboardSkeleton />;
  }

  return (
    <>
      <div className="flex flex-1 flex-col p-0">
        <div className="mb-3 flex flex-row justify-between mt-2">
          <Typography variant="h1">Device Library</Typography>
          <Button
            variant="contained"
            endIcon={<AddRounded />}
            onClick={async () => {
              setNewDeviceModalOpen(true);
            }}
          >
            Add Device
          </Button>
        </div>

        {!isLoadingDevices && devices && devices.length > 0 && (
          <DeviceList devices={devices} />
        )}
        {!isLoadingDevices && devices && devices.length === 0 && (
          <div className="flex max-w-md flex-1 items-center justify-center self-center text-center">
            <Typography variant="body1">
              To start click the 'Add device' button in the top right. This will
              create your first device and lead you right into the guided
              technical file.
            </Typography>
          </div>
        )}
      </div>
      <DeviceCreationModal
        open={newDeviceModalOpen}
        onClose={() => setNewDeviceModalOpen(false)}
        subscription={subscription}
        user={user}
        devices={devices}
      />
    </>
  );
}

export default Dashboard;
