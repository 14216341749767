import { Tooltip } from "@mui/material";
import { RoadmapCard, RoadmapCardId, RoadmapStageId } from "../../config";

type Props = {
  roadmap: {
    title: string;
    id: RoadmapStageId;
    disabled?: boolean;
    cards: RoadmapCard[];
  }[];

  onClickOpenCardDialog: (
    cardId: RoadmapCardId,
    stageId: RoadmapStageId
  ) => void;
};

export const Cards = (props: Props) => {
  const { roadmap, onClickOpenCardDialog } = props;

  return (
    <div className="flex">
      {roadmap.map((step) => {
        return (
          <div className="mt-3 flex w-1/5 flex-col gap-y-2" key={step.title}>
            {step.cards.map((card) => {
              const completed = card.tasks.every((task) => task.completed);

              const disabled = step.disabled || card.enabled === false;

              let background = completed ? "bg-green-600" : "bg-slate-200";
              let backgroundHover = completed
                ? "hover:bg-green-700"
                : "hover:bg-slate-300";

              const textColor = completed ? "text-white" : "text-default";
              return (
                <Tooltip
                  title={"Complete the previous stage to unlock"}
                  key={card.title}
                  followCursor
                  disableHoverListener={!disabled}
                >
                  <div
                    key={card.title}
                    onClick={() =>
                      !disabled && onClickOpenCardDialog(card.id, step.id)
                    }
                    className={`${background} ${textColor} ${backgroundHover} relative mr-[6px] flex h-28 cursor-pointer items-center justify-center gap-x-2 rounded-sm p-3 text-center`}
                  >
                    {disabled && (
                      <div className="absolute bottom-0 left-0 right-0 top-0  z-10   bg-slate-100/60"></div>
                    )}
                    {card.title}
                  </div>
                </Tooltip>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
