import {
  CloudDownloadOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { config as TemplateAssistantConfig } from "../../../../config/assistant";

import { TEMPLATE_TYPE } from "@models";
import { BaseAnimation } from "./BtnAnimation";

const FileDownload = ({
  handleFileDownload,
  templateId,
}: {
  handleFileDownload: () => void;
  templateId: TEMPLATE_TYPE;
}) => (
  <BaseAnimation
    Icon={FileDownloadOutlined}
    text={`Download the ${TemplateAssistantConfig[templateId].docName} here.`}
    buttonText={"Download"}
    onClick={handleFileDownload}
    ButtonIcon={CloudDownloadOutlined}
    animated={false}
  />
);
export default FileDownload;
