import { useEffect, useState } from "react";
import { templatesWithUpload } from "src/config";
import {
  deleteApproverMutation,
  postApproverMutation,
  useGetDocumentApproversQuery,
  useGetUser,
  useGetUserById,
  usePatchDocumentVersionMutation,
} from "../../../../../hooks";
import {
  BaseUser,
  DocumentVersion,
  DocumentVersionApprover,
  TEMPLATE_TYPE,
} from "../../../../../stores/models";
import {
  getDocVersionRevisionNumber,
  isDocumentApproved,
} from "../../../../../utils";
import ApproverContent from "./ApproverContent";
import ApproversTabContentLoading from "./ApproversTabContentLoading";
import AuthorContent from "./AuthorContent";
import CompleteScreen from "./CompleteScreen";
import { NotInvolvedContent } from "./NotInvolvedContent";

const requiredNumberOfApprovers = 2;

export const ApproversTabContent = ({
  selectedDocVersion,
  docVersions,
  deviceId,
  generatedDoc,
  templateId,
}: {
  docVersions: DocumentVersion[];
  selectedDocVersion: DocumentVersion;
  deviceId: string;
  templateId: TEMPLATE_TYPE;
  generatedDoc?: string;
}) => {
  const { data: user } = useGetUser();
  const { data: docVersionAuthor } = useGetUserById({
    userId: selectedDocVersion?.createdBy,
  });

  const { data: approvers } = useGetDocumentApproversQuery({
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
  });

  const [enableApproversEditing, setEnableApproversEditing] = useState(true);
  const [localRevisionSummary, setLocalRevisionSummary] = useState("");

  const [selectedUsers, setSelectedUsers] = useState<Array<BaseUser | null>>(
    []
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (approvers) {
      setSelectedUsers(approvers.map((approver) => approver.user));
    }

    if (approvers?.length === requiredNumberOfApprovers) {
      setEnableApproversEditing(false);
    }
  }, [approvers]);

  if (!selectedDocVersion) {
    return <ApproversTabContentLoading />;
  }

  const approverMutation = postApproverMutation({
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
    deviceId,
  });

  const removeApproverMutation = deleteApproverMutation({
    documentId: selectedDocVersion.documentId,
    versionId: selectedDocVersion.id,
    deviceId,
  });

  const currentUserApprover = approvers?.find(
    (approver) => approver.userId === user?.id
  );

  let approver: DocumentVersionApprover | null = null;

  if (currentUserApprover) {
    approver = currentUserApprover;
  }

  const patchDocumentMutation = usePatchDocumentVersionMutation();

  const handleSave = async (
    approvers: DocumentVersionApprover[],
    selectedUsers: BaseUser[],
    revisionSummary: string
  ) => {
    setEnableApproversEditing(false);
    setSaving(true);

    const newUsers = selectedUsers.filter(
      (s) => !approvers.find((a) => a.user.id === s?.id)
    );
    const removedUsers = approvers.filter(
      (a) => !selectedUsers.find((s) => s?.id === a.user.id)
    );

    // For each approver that got removed, remove the approver from the backend and for each approver that got added, add the approver to the backend
    const deletedUsers = await Promise.all(
      removedUsers.map(async (approver) => {
        const { status } = await removeApproverMutation.mutateAsync({
          approverId: approver.id,
        });
        return status;
      })
    );

    if (deletedUsers.every((result) => result === 200)) {
      await Promise.all(
        newUsers.map(async (user) => {
          await approverMutation.mutateAsync({
            userId: user.id,
          });
        })
      );
    }

    const versionNeedsSnapshot = !templatesWithUpload.includes(templateId);

    let snapshot = selectedDocVersion.snapshot;

    if (versionNeedsSnapshot && !snapshot) {
      if (!generatedDoc) {
        throw new Error(
          "To submit this revision the generated document is required but missing."
        );
      }
      snapshot = generatedDoc;
    }

    if (!snapshot && versionNeedsSnapshot) {
      throw new Error("Snapshot needs to be defined but is missing.");
    }

    if (revisionSummary !== selectedDocVersion.revisionSummary) {
      await patchDocumentMutation.mutateAsync({
        docId: selectedDocVersion.documentId,
        docVersionId: selectedDocVersion.id,
        deviceId,
        data: {
          revisionSummary,
          ...(versionNeedsSnapshot &&
          snapshot &&
          snapshot !== selectedDocVersion.snapshot
            ? { snapshot }
            : {}),
        },
      });
    }
    setLocalRevisionSummary("");
    setSaving(false);
  };

  if (!user || approvers === undefined || !docVersionAuthor) {
    return <ApproversTabContentLoading />;
  }

  const userIsAuthor = selectedDocVersion.createdBy === user.id;

  if (approvers && isDocumentApproved({ approvers: approvers })) {
    return (
      <CompleteScreen
        approvers={approvers}
        author={docVersionAuthor}
        docVersion={selectedDocVersion}
        revision={getDocVersionRevisionNumber(selectedDocVersion, docVersions)}
        revisionSummary={selectedDocVersion.revisionSummary}
      />
    );
  }

  if (!approver && !userIsAuthor) {
    return (
      <NotInvolvedContent
        approvers={approvers}
        docVersionAuthor={docVersionAuthor}
        requiredNumberOfApprovers={requiredNumberOfApprovers}
      />
    );
  }

  if (approver && !userIsAuthor) {
    return (
      <ApproverContent
        approvers={approvers}
        revisionSummary={selectedDocVersion.revisionSummary}
        selectedDocVersion={selectedDocVersion}
        approver={approver}
      />
    );
  }

  if (userIsAuthor) {
    return (
      <AuthorContent
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        revisionSummary={
          localRevisionSummary || selectedDocVersion.revisionSummary
        }
        saving={saving}
        setRevisionSummary={setLocalRevisionSummary}
        enableApproversEditing={enableApproversEditing}
        setEnableApproversEditing={setEnableApproversEditing}
        author={docVersionAuthor}
        handleSave={handleSave}
        savedRevisionSummary={selectedDocVersion.revisionSummary}
        savedApprovers={approvers}
      />
    );
  }
};

export default ApproversTabContent;
