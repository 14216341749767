import { ChipOwnProps } from "@mui/material";
import moment from "moment";
import {
  BaseUser,
  DocumentVersionApprover,
  DocumentVersionApproverStatus,
} from "../../../../stores/models";

export const docInfoDrawerTabs = {
  approvers: "Approvers",
  activity: "Activity",
  revisions: "Revisions",
  docInfo: "Doc Info",
} as const;

export interface UserState extends BaseUser {
  approvalStatus?: keyof typeof DocumentVersionApproverStatus;
}

export interface Approver extends BaseUser {
  approvalStatus?: keyof typeof DocumentVersionApproverStatus;
}

export type AssignedUsers = (UserState | null)[];

export type EmptyObj = Record<PropertyKey, never>;

export const emptyUser = { firstName: "", lastName: "", email: "" };

export const userStatusDictionary: Record<
  string,
  { text: string; variant: ChipOwnProps["color"] }
> = {
  USER_INVITE_SENT: { text: "Invitation is pending", variant: "warning" },
  APPROVED: { text: "Approved on", variant: "success" },
  AWAITING_SIGNATURE: { text: "Awaiting approval", variant: "warning" },
  AUTHORED: { text: "Authored", variant: "success" },
};

export const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

export const getApproverStatusText = (approver: DocumentVersionApprover) => {
  const date = formatDate(new Date(approver.updatedAt));
  return `${userStatusDictionary[approver.approvalStatus].text} ${approver.approvalStatus === DocumentVersionApproverStatus.APPROVED ? date : ""}`;
};
