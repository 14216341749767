import { Document, DocumentVersion, TEMPLATE_TYPE, User } from "@models";
import { Edit } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { DocStatus } from "@types";
import { useEffect } from "react";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { ASSISTANT_CONFIG } from "src/config";
import { getDocVersionStatus } from "src/utils";
import DocVersionDropDown from "../docViewerTopBar/selects/DocVersionDropdown";
import useOutstandingAction from "../hooks/useOutstandingAction";
import { VisuallyHiddenInput } from "./BtnAnimation";

type FileDocViewerTopBarProps = {
  selectedDoc: Document;
  selectedDocVersion: DocumentVersion;
  selectDocumentVersion: (docVersion: DocumentVersion) => void;
  showDocInfoDrawer: boolean;
  selectLoading: boolean;
  handleEditClick: () => void;
  handleEditCancel: () => void;
  editConfirmationOpen: boolean;
  setEditConfirmationOpen: (open: boolean) => void;
  toggleDrawer: () => void;
  handleCreateNewVersionWithFile: (
    doc: Document,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  user: User;
  templateId: TEMPLATE_TYPE;
};

export const FileDocViewerTopBar = ({
  selectedDoc,
  selectedDocVersion,
  selectDocumentVersion,
  showDocInfoDrawer,
  selectLoading,
  handleEditClick,
  handleEditCancel,
  editConfirmationOpen,
  setEditConfirmationOpen,
  toggleDrawer,
  handleCreateNewVersionWithFile,
  user,
  templateId,
}: FileDocViewerTopBarProps) => {
  useEffect(() => {
    outstandingAction.reset();
  }, [templateId]);

  const docConfig = ASSISTANT_CONFIG[templateId];

  const outstandingAction = useOutstandingAction({
    document: selectedDoc,
    selectedDocVersionId: selectedDocVersion.id,
    user,
    setDocInfoDrawerOpen: toggleDrawer,
    selectedDocVersion: selectedDocVersion,
  });

  const docVersionStatus = getDocVersionStatus(
    selectedDocVersion,
    selectedDoc.versions
  );

  const getEditButton = (docVersionStatus: DocStatus) => {
    const isLiveOrArchived = ["LIVE", "ARCHIVED"].includes(docVersionStatus);
    const isDraft = docVersionStatus === "DRAFT";
    const docHasDraft = selectedDoc.versions
      .map((v) => getDocVersionStatus(v, selectedDoc.versions))
      .some((s) => s === "DRAFT");

    if (isLiveOrArchived && docHasDraft) {
      return (
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => selectDocumentVersion(selectedDoc.versions[0])}
        >
          Edit draft
        </Button>
      );
    }
    if (isLiveOrArchived && !docHasDraft) {
      return (
        <Button variant="outlined" color="inherit" onClick={handleEditClick}>
          New revision
        </Button>
      );
    }
    if (isDraft) {
      return (
        <IconButton
          disabled={selectLoading}
          color="inherit"
          size="small"
          onClick={handleEditClick}
        >
          {selectLoading ? (
            <CircularProgress size={"1rem"} color="inherit" />
          ) : (
            <Edit fontSize="small" />
          )}
        </IconButton>
      );
    }
    return null;
  };

  return (
    <div className="flex items-center justify-between rounded-md">
      {selectedDoc && selectedDocVersion && (
        <>
          <div>
            <DocVersionDropDown
              docVersions={selectedDoc.versions}
              selectedVersion={selectedDocVersion}
              onSelectDocVersion={(docVersion: DocumentVersion) => {
                selectDocumentVersion(docVersion);
                if (showDocInfoDrawer) {
                  outstandingAction.reset();
                }
              }}
              showStatus={!["TMP", "EXT"].includes(docConfig.docType)}
            />
          </div>
          <div className="flex items-center gap-2">
            <>
              {docVersionStatus && getEditButton(docVersionStatus)}
              <ConfirmationModal
                key="confirm-edit"
                title="Edit document"
                confirmText="Confirm"
                content="Are you sure you want to edit this document? This will create a new draft revision or replace the current draft."
                onAbort={handleEditCancel}
                open={editConfirmationOpen}
                confirmButtonProps={{
                  component: "label",
                }}
                children={
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => {
                      setEditConfirmationOpen(false);
                      handleCreateNewVersionWithFile(selectedDoc, e);
                    }}
                    accept=".pdf, .txt, .docx, .png, .jpeg"
                    max={1}
                  />
                }
              />
            </>
            <span className="relative inline-flex">
              <Tooltip
                enterDelay={500}
                title={
                  <>
                    Toggle approval <kbd>A</kbd>
                  </>
                }
                placement="bottom"
                disableInteractive
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -12],
                        },
                      },
                    ],
                  },
                }}
              >
                <Button
                  onClick={toggleDrawer}
                  size="medium"
                  variant="outlined"
                  color="inherit"
                >
                  Approval
                </Button>
              </Tooltip>
              {outstandingAction.shouldShow && (
                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-800 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-700"></span>
                </span>
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
