import { Alert, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES } from "src/config";

export const Banner = ({
  bannerText,
  buttonText,
  buttonLink,
}: {
  bannerText: string;
  buttonText: string;
  buttonLink: ROUTES;
}) => {
  return (
    <Alert
      severity="warning"
      icon={false}
      className="w-full max-h-[46px] animate-fade-down animate-duration-500 px-5"
      action={
        <Button
          color="inherit"
          size="small"
          variant="outlined"
          fullWidth
          component={Link}
          // To counter the margin added by the Alert component
          className="mr-2"
          to={buttonLink}
        >
          {buttonText}
        </Button>
      }
      sx={{
        display: "flex",
        alignItems: "center",
        ".MuiAlert-message": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          flex: 1,
          marginRight: "16px",
        },
        ".MuiAlert-action": {
          padding: 0,
          marginLeft: 6,
        },
      }}
    >
      {bannerText}
    </Alert>
  );
};
