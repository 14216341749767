import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

// Add VITE_USER_NODE_ENV=true to the .env file for local testing
Sentry.init({
  enabled:
    window._env_.ENV === "production" ||
    window._env_.ENV === "development" ||
    import.meta.env.VITE_TEST_LOCAL_SENTRY === "true",
  debug: import.meta.env.VITE_TEST_LOCAL_SENTRY === "true",
  dsn: window._env_.SENTRY_DSN,
  environment: window._env_.ENV || "local",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", window._env_.BACKEND_BASE_URL],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
