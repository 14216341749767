import { Button, Typography } from "@mui/material";
import {
  DocumentVersionApprover,
  DocumentVersionApproverStatus,
} from "../../../../../stores/models";
import { buttonsDictionary } from "./ApproverContent";

export const ApproverActions = ({
  handleApprove,
  approver,
}: {
  handleApprove: () => void;
  approver: DocumentVersionApprover;
}) => {
  const status =
    approver.approvalStatus || DocumentVersionApproverStatus.AWAITING_SIGNATURE;
  return (
    <div>
      <div>
        <Typography variant="subtitle1" fontWeight="bold">
          You’ve been assigned this document.
        </Typography>
        <Typography variant="subtitle2" className="pb-2">
          Please review the document thoroughly and approve it.
        </Typography>
      </div>
      <div className="flex gap-3">
        <Button
          variant="contained"
          color={buttonsDictionary[status].color}
          onClick={handleApprove}
        >
          {buttonsDictionary[status].text}
        </Button>
      </div>
    </div>
  );
};

export default ApproverActions;
