import { ObjectSchema, date, object, string } from "yup";
import { RoadmapTaskId } from "../../config";

export const roadmapTasksSchema = object({
  id: string().uuid().required(),
  tasks: object(),
  deviceId: string().uuid().required(),
  createdBy: string().uuid().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
}) as ObjectSchema<RoadmapTasks>;

export type RoadmapTasks = {
  id: string;
  tasks: Record<RoadmapTaskId, boolean>;
  deviceId: string;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
};
