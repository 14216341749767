import { Snackbar as MuiSnackbar, Slide } from "@mui/material";
import { useSnackbarContext } from "./useSnackbar";

export const Snackbar = () => {
  const { open, message, handleClose } = useSnackbarContext();

  return (
    <MuiSnackbar
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id="snackbar"
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      message={message}
    />
  );
};
