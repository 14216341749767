import * as yup from "yup";
import { boolean, date, object, string } from "yup";

export const roadmapQuestionnaireSchema = object({
  id: string().required(),
  humanContact: boolean().required(),
  usesSoftware: boolean().required(),
  incorporatesAI: boolean().required(),
  incorporatesHumanCells: boolean().required(),
  incorporatesAnimalCells: boolean().required(),
  sterilizationIntended: boolean().required(),
  containsCmr: boolean().required(),
  reprocessingIntended: boolean().required(),
  electricalParts: boolean().required(),
  classTwo: boolean().required(),
  incorporatesMedicinalSubstances: boolean().required(),
  customMade: boolean().required(),
  softwareOnly: boolean().required(),
  deviceId: string().required(),
  createdBy: string().required(),
  createdAt: date().required(),
  updatedAt: date().required(),
});

export interface RoadmapQuestionnaire
  extends yup.InferType<typeof roadmapQuestionnaireSchema> {}
