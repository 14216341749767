import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  ROADMAP_CARDS,
  ROADMAP_STAGES,
  ROADMAP_TASKS,
  ROUTES,
  RoadmapCard,
  RoadmapStageId,
  RoadmapTask,
  getTasksQueryKey,
} from "../config";
import { useDocEngine, useGetDocuments, useGetTasks } from "../hooks";
import { patchRoadmapTasks } from "../services";
import { RoadmapTasks, TEMPLATE_TYPE } from "../stores/models";
import { captureSentryMessage, isDocumentUnlocked } from "../utils";

type Props = {
  cardConfig: { card: RoadmapCard; phase: RoadmapStageId };
  deviceId: string;
  open: boolean;
  handleCloseCardDialog: () => void;
};

export const TaskModal = (props: Props) => {
  const {
    cardConfig: { card: selectedCard },
    deviceId,
    open,
    handleCloseCardDialog,
  } = props;
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={handleCloseCardDialog}>
      <DialogTitle>{selectedCard.title}</DialogTitle>
      <DialogContent className="flex flex-col items-start gap-y-4">
        {/* Leave here to showcase ability to define custom content for a card */}
        {selectedCard.customContent && null}

        <div className="flex w-full flex-1 flex-col">
          <Typography variant="body1" className="mb-2 font-bold">
            Tasks
          </Typography>
          <div className="flex flex-col gap-y-2">
            <TaskContent {...props} />
          </div>
        </div>
        <Divider className="w-full" />
        <div>
          <Typography variant="body1" className="mb-2 font-bold">
            Description
          </Typography>
          {selectedCard.tasksDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: selectedCard.tasksDescription,
              }}
              className="whitespace-pre-wrap"
            />
          )}
        </div>
        {selectedCard.afterTasksDescription && (
          <Typography
            variant="body1"
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: selectedCard.afterTasksDescription,
            }}
          />
        )}
        {selectedCard.techfileDescription && (
          <Typography
            variant="body1"
            className="whitespace-pre-wrap"
            dangerouslySetInnerHTML={{
              __html: selectedCard.techfileDescription,
            }}
          />
        )}
        {selectedCard.helpCenterArticleId && (
          <Link
            component={RouterLink}
            underline="hover"
            to={ROUTES.HELP_CENTER_ARTICLE.replace(
              ":articleId",
              selectedCard.helpCenterArticleId
            )}
          >
            Need more help? Read the detailed guide in the help center
          </Link>
        )}
      </DialogContent>
      <DialogActions className="flex justify-between">
        <Button onClick={handleCloseCardDialog}>Close</Button>
        {selectedCard.link && (
          <Button
            variant="contained"
            onClick={() =>
              selectedCard.link &&
              navigate(selectedCard.link.replace(":deviceId", deviceId))
            }
          >
            Open Technical File Builder
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const TaskContent = ({
  deviceId,
  cardConfig: { card: selectedCard },
}: Props) => {
  const [taskHovered, setTaskHovered] = useState<TEMPLATE_TYPE | undefined>(
    undefined
  );
  const docEngine = useDocEngine(deviceId);

  const { data: tasks } = useGetTasks(deviceId);
  const { data: documents } = useGetDocuments(deviceId);

  const [loadingTaskId, setLoadingTaskId] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const taskMutation = useMutation({
    mutationFn: (updatedTasks: Record<string, boolean>) => {
      return patchRoadmapTasks(deviceId, updatedTasks);
    },
    onMutate: async (updatedTasks: Record<string, boolean>) => {
      queryClient.setQueryData(
        getTasksQueryKey(deviceId),
        (old: RoadmapTasks) => ({
          ...old,
          tasks: updatedTasks,
        })
      );
    },
  });
  const handleTaskClick = async (task: RoadmapTask) => {
    setLoadingTaskId(task.id);
    // check if task.id is of type TEMPLATE_TYPE
    let docName: TEMPLATE_TYPE | undefined;
    if (Object.values(TEMPLATE_TYPE).includes(task.id as any))
      docName = task.id as TEMPLATE_TYPE;

    // if the the tasks is of type TEMPLATE_TYPE, we need to check more things. Otherwise we can just navigate to the link
    if (docName) {
      await docEngine.navigateToWizardOrQMS({
        templateId: docName,
      });
    } else {
      captureSentryMessage(
        "Task Modal was clicked but no where to go. Missing an old formId?"
      );
    }
  };
  if (tasks && documents)
    return (
      selectedCard.tasks
        // Only list tasks on the card where there is no visibility condition or where the condition is met
        .filter((task) => {
          if (task.visibilityCondition) {
            const answer = documents
              .map((doc) =>
                doc.versions[0]?.answers.find(
                  (answer) =>
                    answer.element === task.visibilityCondition?.dataKey
                )
              )
              .find((answer) => answer !== undefined);

            if (!answer || !answer.answer) {
              // If the answer is not present, we should not show the task until we have more certainty
              return false;
            }

            return task.visibilityCondition.isVisible(answer.answer);
          }
          return true;
        })
        .map((task) => {
          // Check if every document that is mentioned inside the context config in the TemplateAssistantConfig is present in the documents array and marked as completed
          // We need to check if we have a valid template type because task id can also be a different string
          let templateType: TEMPLATE_TYPE | undefined;
          if (Object.values(TEMPLATE_TYPE).includes(task.id as any))
            templateType = task.id as TEMPLATE_TYPE;
          if (!templateType) return null;

          const { enabled, incompleteDocuments } = isDocumentUnlocked(
            templateType,
            documents
          );

          return (
            <div key={task.title} className="flex gap-x-1 items-center">
              {loadingTaskId === task.id ? (
                <div className="min-w-[39px] h-[39px] flex items-center justify-center">
                  <CircularProgress size={16} />
                </div>
              ) : (
                <Checkbox
                  checked={task.completed}
                  disabled={!enabled}
                  onClick={() => {
                    taskMutation.mutate({
                      ...tasks.tasks,
                      [task.id]: !task.completed,
                    });
                  }}
                />
              )}
              <div className="flex items-center">
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "lg",
                        width: "fit-content",
                      },
                    },
                  }}
                  title={
                    <Typography color="inherit" variant="caption">
                      To unlock this task you need to complete the following
                      tasks first:
                      <br />
                      {incompleteDocuments
                        ?.map((c) => {
                          const task = ROADMAP_TASKS.find((t) => t.id === c);
                          const card = ROADMAP_CARDS.find(
                            (c) => c.id === task?.card
                          );

                          const stage = ROADMAP_STAGES.find(
                            (s) => s.id === card?.stage
                          );
                          return (
                            stage?.title +
                            " > " +
                            card?.title +
                            " > " +
                            task?.title
                          );
                        })
                        .map((title, index, array) => (
                          <Fragment key={index}>
                            &#x2022; {title}
                            {index < array.length - 1 && <br />}
                          </Fragment>
                        ))}
                    </Typography>
                  }
                  open={taskHovered === templateType}
                  followCursor
                >
                  <Link
                    className={`flex items-center cursor-pointer ${
                      incompleteDocuments && " text-gray-400"
                    }`}
                    onMouseEnter={() =>
                      incompleteDocuments && setTaskHovered(templateType)
                    }
                    onMouseLeave={() =>
                      incompleteDocuments && setTaskHovered(undefined)
                    }
                    underline="hover"
                    onClick={() => {
                      if (enabled) handleTaskClick(task);
                    }}
                  >
                    {task.title}
                  </Link>
                </Tooltip>
              </div>
            </div>
          );
        })
    );

  return (
    <>
      <Skeleton
        variant="rectangular"
        key={0}
        width="100%"
        height={28}
        className="mb-2"
      />
      <Skeleton
        variant="rectangular"
        key={1}
        width="90%"
        height={28}
        className="mb-2"
      />
      <Skeleton
        variant="rectangular"
        key={2}
        width="100%"
        height={28}
        className="mb-2"
      />
    </>
  );
};
