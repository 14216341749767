import { useAuth0 } from "@auth0/auth0-react";
import { useGetUser } from "@hooks";
import { Plan } from "@models";
import { Box, Dialog, useTheme } from "@mui/material";
import * as Sentry from "@sentry/react";
import { getSubscription } from "@utils";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Fallback, NavBar } from "..";
import { useStore } from "../../stores";
import Welcome from "../Onboarding/Welcome";
import { FixedToolbarSpacer } from "./FixedToolbarSpacer";
import { LeftDrawer } from "./LeftDrawer";

const leftDrawerWidth = 240;
const leftDrawerWidthCollapsed = 56;

type Props = {
  children?: React.ReactNode;
};

export function MainLayout(props: Props) {
  const { uiStore } = useStore();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState<boolean>(false);

  const { isAuthenticated } = useAuth0();
  const { data: user } = useGetUser(isAuthenticated);
  const subscription = user ? getSubscription(user) : null;

  useEffect(() => {
    const shouldOpenOnboarding = localStorage.getItem("shouldOpenOnboarding");

    if (shouldOpenOnboarding === null || shouldOpenOnboarding === "true") {
      setIsOnboardingOpen(true);
    }
  }, []);

  useEffect(() => {
    const isUserMissingInfo = user && (!user?.firstName || !user?.lastName);

    if (isUserMissingInfo) {
      setIsOnboardingOpen(true);
    }
  }, [user]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const hasBanner = subscription?.product === Plan.FREE;

  return (
    <>
      <div className="flex h-full">
        <NavBar
          onHandleDrawer={handleDrawer}
          isDrawerOpen={open}
          hasBanner={hasBanner}
        />
        <LeftDrawer
          open={open}
          onHandleDrawer={handleDrawer}
          width={leftDrawerWidth}
          collapsedWidth={leftDrawerWidthCollapsed}
          hasBanner={hasBanner}
        />
        <Box
          className="flex flex-1 flex-col overflow-x-auto lg:overflow-x-hidden"
          component="main"
          ref={uiStore.mainRef}
          sx={{
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${leftDrawerWidth}px + ${leftDrawerWidthCollapsed}px`,
            ...(open && {
              transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }),
              marginLeft: 0,
            }),
          }}
        >
          {/* Do not remove this toolbar and alert it pushes the content down and for some reason sticky doesn't work https://mui.com/material-ui/react-app-bar/#fixed-placement */}
          <FixedToolbarSpacer hasBanner={hasBanner} />
          {/* End of do not remove */}

          <div className="flex flex-1 flex-col overflow-y-auto px-5 py-3">
            <Sentry.ErrorBoundary fallback={(props) => <Fallback {...props} />}>
              {props.children || <Outlet />}
            </Sentry.ErrorBoundary>
          </div>
        </Box>
      </div>
      {!!user && (
        <Dialog
          className="rounded"
          open={isOnboardingOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              maxWidth: "100%",
            },
          }}
        >
          <Welcome user={user} setIsOnboardingOpen={setIsOnboardingOpen} />
        </Dialog>
      )}
    </>
  );
}
