import CloseIcon from "@mui/icons-material/Close";
import {
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useKeyboardShortcuts } from "src/hooks/useKeyboardShortcuts";
import { DocumentVersion, TEMPLATE_TYPE } from "../../../../stores/models";
import ApproversTabContent from "./approversTab/ApproversTabContent";

export const DocInfoDrawer = ({
  isOpen,
  onDrawerClose,
  onDrawerOpen,
  selectedDocVersion,
  docVersions,
  deviceId,
  generatedDoc,
  templateId,
}: {
  isOpen: boolean;
  onDrawerClose: () => void;
  onDrawerOpen: () => void;
  selectedDocVersion: DocumentVersion;
  docVersions: DocumentVersion[];
  deviceId: string;
  templateId: TEMPLATE_TYPE;
  generatedDoc?: string;
}) => {
  useKeyboardShortcuts({
    Escape: () => {
      if (isOpen) {
        onDrawerClose();
      }
    },
    a: () => {
      if (!isOpen) {
        onDrawerOpen();
      }
    },
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`animate-in zoom-in sticky bottom-0 flex w-full justify-center`}
    >
      <Paper
        elevation={10}
        className="h-fit w-11/12 rounded-lg border border-solid border-slate-200 bg-white pt-2 p-4 shadow-xl relative flex flex-col"
      >
        <Typography
          variant="h6"
          className="w-full flex justify-between items-center"
        >
          Approval
          <IconButton aria-label="close" onClick={onDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Typography>
        <Divider className="w-full" />
        <DialogContent className="w-full flex flex-col justify-between p-0 pt-2">
          <ApproversTabContent
            selectedDocVersion={selectedDocVersion}
            docVersions={docVersions}
            deviceId={deviceId}
            generatedDoc={generatedDoc}
            templateId={templateId}
          />
        </DialogContent>
      </Paper>
    </div>
  );
};

export default DocInfoDrawer;
