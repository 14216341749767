import config from "./config";
import ARTICLES from "./helpcenter/articles";

export type Config = typeof config;
export default config;
export * from "./assistant";
export { config as ASSISTANT_CONFIG, templatesWithUpload } from "./assistant";
export * from "./helpcenter/articles";
export { ROUTES } from "./navigation/routes";
export * from "./request-keys";
export * from "./roadmap";
export { theme } from "./theme";
export { ARTICLES };

export const ALWAYS_AVAILABLE_CARDS = [
  "device-basic-characteristics",
  "software-processes",
  "device-classification",
  "establish-risk-management-system",
  "establish-quality-management-system",
  "user-needs",
];

export const DEVICE_CHARACTERISTICS_QUESTIONNAIRE = {
  humanContact: {
    question: "Does your product come in contact with the human body?",
    statement: "The product comes in contact with the human body.",
    id: "humanContact",
  },
  usesSoftware: {
    question: "Does your product use software?",
    statement: "The product uses software",
    id: "usesSoftware",
  },
  incorporatesAI: {
    question: "Does your product incorporate artificial intelligence?",
    statement: "The product incorporates artificial intelligence.",
    id: "incorporatesAI",
  },
  softwareOnly: {
    question:
      "Does the product you intended to certify only a software application without physical parts?",
    statement: "The product is a software only application.",
    id: "softwareOnly",
  },
  incorporatesHumanCells: {
    question:
      "Does your product incorporate cells from human origin or their derivatives?",
    statement:
      "The product incorporates cells from human origin or their derivatives.",
    id: "incorporatesHumanCells",
  },
  incorporatesAnimalCells: {
    question:
      "Does your product incorporate cells from animal origin or their derivatives?",
    statement:
      "The product incorporates cells from animal origin or their derivatives.",
    id: "incorporatesAnimalCells",
  },
  sterilizationIntended: {
    question:
      "Is your product intended to be sterilized or provided in a defined microbiological condition?",
    statement:
      "The product is intended to be sterilized or provided in a defined microbiological condition.",
    id: "sterilizationIntended",
  },
  containsCmr: {
    question:
      "Does your product contain known carcinogenic, mutagenic or toxic to reproduction (CMR) substances or known endocrine-disrupting substances?",
    statement:
      "The product contains known carcinogenic, mutagenic or toxic to reproduction (CMR) substances or known endocrine-disrupting substances.",
    id: "containsCmr",
  },
  reprocessingIntended: {
    question:
      "Is your product intended to be reprocessed or is not a single use product?",
    statement:
      "The product is intended to be reprocessed or is not a single use product.",
    id: "reprocessingIntended",
  },
  electricalParts: {
    question: "Does your product contain electrical parts?",
    statement: "The product contains electrical parts.",
    id: "electricalParts",
  },
  classTwo: {
    question: "Is your product Class IIa or above according to MDR 2017/745?",
    statement: "The product is Class IIa or above according to MDR 2017/745.",
    id: "classTwo",
  },
  incorporatesMedicinalSubstances: {
    question:
      "Does your product incorporate a medicinal substance as an integral part of the device?",
    statement:
      "The product incorporates a medicinal substance as an integral part of the device.",
    id: "incorporatesMedicinalSubstances",
  },
  customMade: {
    question:
      "Is your product a 3D printed and/or custom-made product for each patient?",
    statement:
      "The product is a 3D printed and/or custom-made product for each patient.",
    id: "customMade",
  },
};
