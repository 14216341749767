import { DocumentVersionApproverStatus } from "../../../../../stores/models";
import {
  DefineOutstandingActionProps,
  HandleOutstandingActionProps,
  OutstandingAction,
} from "./handleOutstandingAction.types";

export const handleOutstandingAction = ({
  user,
  outstandingAction,
  setDocInfoDrawerOpen,
  setOutstandingAction,
  selectedDocVersion,
}: HandleOutstandingActionProps) => {
  const userIsAuthor = selectedDocVersion.createdBy === user.id || false;
  const approvers = selectedDocVersion.documentVersionApprover || [];
  const hasApprovers = approvers.length === 2;

  const approver = approvers.find((approver) => approver.userId === user.id);
  const hasApproverApproved =
    approver?.approvalStatus === DocumentVersionApproverStatus.APPROVED;
  const userIsApprover = !!approver;

  if (userIsAuthor && !userIsApprover) {
    defineOutstandingAction({
      condition: hasApprovers,
      action: OutstandingAction.SELECT_APPROVERS,
      outstandingAction,
      setDocInfoDrawerOpen,
      setOutstandingAction,
    });
    return;
  }

  if (userIsApprover)
    defineOutstandingAction({
      condition: hasApproverApproved,
      action: OutstandingAction.APPROVAL_PENDING,
      outstandingAction,
      setDocInfoDrawerOpen,
      setOutstandingAction,
    });
};

const defineOutstandingAction = ({
  condition,
  action,
  outstandingAction,
  setOutstandingAction,
}: DefineOutstandingActionProps) => {
  const hasOutstandingAction = outstandingAction !== action;
  if (!condition && hasOutstandingAction) {
    setOutstandingAction(action);
  }

  const isOutstandingActionDone =
    outstandingAction === OutstandingAction.ALL_DONE;
  if (condition && !isOutstandingActionDone) {
    setOutstandingAction(OutstandingAction.ALL_DONE);
  }
};
