import { HelpOutline } from "@mui/icons-material";
import { Tooltip, Typography } from "@mui/material";

interface DeviceCountSelectorProps {
  deviceCount: number;
  setDeviceCount: React.Dispatch<React.SetStateAction<number>>;
}

export const DeviceCountSelector: React.FC<DeviceCountSelectorProps> = ({
  deviceCount,
  // setDeviceCount,
}) => {
  // const handleDeviceCountChange = (
  //   _: React.MouseEvent<HTMLElement>,
  //   operation: string | null
  // ) => {
  //   if (operation === "add") {
  //     setDeviceCount((prev) => prev + 1);
  //   } else if (operation === "remove") {
  //     setDeviceCount((prev) => Math.max(prev - 1, 1)); // Minimum 1 device
  //   }
  // };

  return (
    <div className="flex items-center">
      <div className="flex gap-2 items-center">
        <Typography variant="body1">Active devices:</Typography>
        <Typography variant="body1" className="min-w-3">
          {deviceCount}
        </Typography>
        <Tooltip
          title={`The number of devices that can actively use your subscription. To add a device create it in the device library. If you delete a device you will no longer be billed for it.`}
          placement="left"
        >
          <HelpOutline fontSize="small" color="primary" />
        </Tooltip>

        {/* <ToggleButtonGroup
          size="small"
          aria-label="Adjust device count"
          value={null}
          onChange={handleDeviceCountChange}
          exclusive
        >
          <ToggleButton value="remove" disabled={deviceCount <= 1}>
            <Remove />
          </ToggleButton>
          <ToggleButton value="add">
            <Add />
          </ToggleButton>
        </ToggleButtonGroup> */}
      </div>
    </div>
  );
};
