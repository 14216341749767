import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Loading } from "./Loading";

export const Signup = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  useEffect(() => {
    loginWithRedirect({
      screen_hint: "signup",
      login_hint: email || "",
    });
  }, []);

  return <Loading />;
};
