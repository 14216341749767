import { chain } from "lodash";
import { makeAutoObservable, runInAction } from "mobx";
import { Editor as TinyMCEEditor } from "tinymce";
import { RootStoreType } from ".";
import config, {
  ASSISTANT_CONFIG,
  DEVICE_CHARACTERISTICS_QUESTIONNAIRE,
} from "../config";
import { Element } from "../pages/MultiStepForm";
import {
  getTemplateDocument,
  patchTemplateDocument,
  postDocumentAnswerSuggestion,
} from "../services";
import { DocumentDataKey, TemplateElement } from "../types";
import { authHelper } from "./helpers";
import {
  Device,
  Document,
  DocumentAnswerSuggestionSchema,
  DocumentVersion,
  TEMPLATE_TYPE,
  TemplateDocument,
  templateDocumentSchema,
} from "./models";

export class DocumentStore {
  rootStore;
  _templates: Map<string, TemplateDocument> = new Map();
  _fetchingState: Map<string, boolean> = new Map();
  _suggestions: Map<string, string> = new Map();
  _editor: TinyMCEEditor | null = null;

  constructor(rootStore: RootStoreType) {
    makeAutoObservable(this, { rootStore: false });
    this.rootStore = rootStore;
  }

  async fetchTemplateDocument(deviceId: string, type: TEMPLATE_TYPE) {
    const { data, status } = await getTemplateDocument(deviceId, type);
    if (status === 200) {
      runInAction(() => {
        this._templates.set(
          `${deviceId}_${type}`,
          templateDocumentSchema.validateSync(data)
        );
      });
    } else if (status === 400) {
      // console.log(import.meta.env.BASE_URL, import.meta.url);
      // console.log(new URL("/templates", import.meta.url));
      const path = `/templates/html/${type}.html`;
      const { href } = new URL(path, import.meta.url);

      const response = await fetch(href);
      const body = await response.text();

      runInAction(() => {
        this._templates.set(
          `${deviceId}_${type}`,
          templateDocumentSchema.validateSync({
            id: `system`,
            deviceId,
            type,
            content: body,
            createdBy: "system",
            createdAt: new Date(),
            updatedAt: new Date(),
          })
        );
      });
    }
  }

  async updateTemplateDocument(
    deviceId: string,
    type: TEMPLATE_TYPE,
    content: string
  ) {
    const document = this._templates.get(`${deviceId}_${type}`);
    if (!document) throw new Error(`Document not found: ${deviceId}_${type}`);

    this._templates.set(`${deviceId}_${type}`, { ...document, content });

    await patchTemplateDocument(deviceId, type, content);
  }

  async *fetchTemplateSuggestions(
    element: TemplateElement,
    deviceName: string,
    deviceId: string,
    description: string,
    characteristics: string[],
    additionalContext?: Array<{ id: DocumentDataKey; value: String }>,
    signal?: AbortSignal
  ) {
    const id = `${deviceId}_${element.id}`;

    this.setFetchingState(id, true);

    let response;
    try {
      response = await fetch(
        config.backends.assistant.baseUrl + "/template-suggestion/v2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + (await authHelper.getAccessTokenSilently()()),
          },
          body: JSON.stringify({
            element_id: element.id,
            device_name: deviceName,
            characteristics,
            description,
            additional_context: additionalContext,
          }),
          signal,
        }
      );
    } catch (error) {
      this.setFetchingState(id, false);
      console.error(error);
    }

    var reader = response?.body?.getReader();
    var decoder = new TextDecoder("utf-8");

    if (!reader) throw new Error("Could not fetch suggestions. Reader is null");
    while (true) {
      const result = await reader.read();
      if (result === null) break;
      if (result.done) break;

      const previous = this._suggestions.get(id) || "";
      let token = decoder.decode(result.value);
      yield previous + token;
    }
    // const result = await reader.read();
    // await processResult(result);
    this.setFetchingState(id, false);
  }

  setEditor = (editor: TinyMCEEditor) => {
    this._editor = editor;
  };

  saveSuggestion = async (
    documentId: string,
    versionId: string,
    element: string,
    suggestion: string
  ) => {
    const { data } = await postDocumentAnswerSuggestion(documentId, versionId, {
      suggestion,
      element,
    });
    return await DocumentAnswerSuggestionSchema.validate(data);
  };

  get documents() {
    return this._templates;
  }

  get suggestions() {
    return this._suggestions;
  }

  get fetchingState() {
    return this._fetchingState;
  }

  savedSuggestion = (documentVersion: DocumentVersion, element: Element) =>
    chain(documentVersion.suggestions)
      .filter((s) => s.element === element.id)
      .orderBy("createdAt")
      .last()
      .value()?.suggestion;

  newSuggestion = (deviceId: string, stepId: string) =>
    this.suggestion(deviceId, stepId);

  generateSuggestion(
    templateId: TEMPLATE_TYPE,
    elementId: string,
    device: Device,
    documents: Document[],
    documentVersion: DocumentVersion,
    signal?: AbortSignal
  ) {
    const isFetching = this.isFetchingSuggestion(device.id, elementId);

    if (isFetching) return;

    const element = ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE].elements.find(
      (e): e is TemplateElement =>
        e.id === elementId && !e.hasOwnProperty("path")
    );
    if (!element) {
      throw new Error(
        `Element with id ${elementId} not found in template ${templateId}`
      );
    }

    const contextDict: Partial<Record<DocumentDataKey, string>> = {};

    // Get all answers from the documentVersion and all other documents
    const answers: DocumentVersion["answers"] = [];

    answers.push(
      ...documents
        .filter((d) => d.versions.length > 0)
        .map((d) => d.versions[0])
        // Filter out the current document and documents without versions
        .filter((v) => v.id !== documentVersion.id)
        .map((v) => v.answers)
        .flat()
    );

    answers.push(...documentVersion.answers);

    // If the context specifies elements that are not in the current document we search for them in the other documents
    answers.forEach((a) => {
      if (element.context?.includes(a.element))
        contextDict[a.element] = a.answer || "";
    });

    if (element.prePromptTransformerConfig) {
      // find the answer for the required input in all the document answers
      const inputs = answers
        .filter((a) =>
          element.prePromptTransformerConfig?.inputs.includes(a.element)
        )
        .map((a) => [a.element, a.answer]);

      if (
        !element.prePromptTransformerConfig.inputs.every((requiredInput) =>
          inputs.find(([dataKey]) => dataKey === requiredInput)
        )
      ) {
        throw new Error(
          "Could not find the saved answer for one of the required inputs of the prePromptTransformerConfig: " +
            JSON.stringify(inputs)
        );
      }

      try {
        // run the transformer
        const adjustedDataKeys = element.prePromptTransformerConfig.transformer(
          Object.fromEntries(inputs)
        );

        Object.entries(adjustedDataKeys).forEach(
          ([key, value]) => (contextDict[key as DocumentDataKey] = value)
        );
      } catch (error) {
        // return a generator that yields the error
        if (error instanceof Error) {
          return (function* () {
            yield `{ "message": "${error.message}", "status": "error"}`;
          })();
        }
      }
    }

    const context = Object.entries(contextDict).map(([k, v]) => ({
      id: k as DocumentDataKey,
      value: v,
    }));

    return this.fetchTemplateSuggestions(
      element,
      device.name,
      device.id,
      device.description,
      this.deviceCharacteristics(device),
      context,
      signal
    );
  }

  deviceCharacteristics = (device: Device) => {
    const questionnaire = device.roadmapQuestionnaire;

    type Entries<T> = {
      [K in keyof T]: [K, T[K]];
    }[keyof T][];

    return (Object.entries(questionnaire) as Entries<typeof questionnaire>)
      .filter(([_, value]) => value === true)
      .filter(([key]) => key in DEVICE_CHARACTERISTICS_QUESTIONNAIRE)
      .map(
        ([key]): keyof typeof DEVICE_CHARACTERISTICS_QUESTIONNAIRE =>
          key as keyof typeof DEVICE_CHARACTERISTICS_QUESTIONNAIRE
      )
      .map((k) => DEVICE_CHARACTERISTICS_QUESTIONNAIRE[k].statement);
  };

  suggestion(deviceId: string, elementId: string) {
    // TODO get with react query
    return this._suggestions.get(`${deviceId}_${elementId}`);
  }

  isFetchingSuggestion(deviceId: string, elementId: string) {
    return this._fetchingState.get(`${deviceId}_${elementId}`);
  }

  setSuggestions(id: string, value: string) {
    // TODO set with react query
    this._suggestions.set(id, value);
  }

  setFetchingState(id: string, value: boolean) {
    this._fetchingState.set(id, value);
  }
}
