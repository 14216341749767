import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { INLINES } from "@contentful/rich-text-types";
import { Skeleton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumps } from "../../components";
import { ARTICLES } from "../../config";
import { useStore } from "../../stores";

export const HelpCenterArticle = observer(() => {
  const { articleId } = useParams<{ articleId: keyof typeof ARTICLES }>();
  const { uiStore } = useStore();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(true);

  if (!articleId) {
    return null;
  }

  const article = ARTICLES[articleId];

  useEffect(() => {
    const loadText = async () => {
      const entry = await uiStore._contentfulClient.getEntry(article.id);
      const rawRichTextField = entry.fields.content as any;
      const options = {
        renderNode: {
          [INLINES.HYPERLINK]: (node: any, next: any) =>
            `<a href="${node.data.uri}" target="_blank">${next(
              node.content
            )}</a>`,
        },
      };
      const htmlString = await documentToHtmlString(rawRichTextField, options);
      setText(htmlString);
      setLoading(false);
    };

    loadText();
  }, [articleId]);

  // const location = useLocation();
  // const pathnames = location.pathname.split("/").filter((x) => x);

  // interface LinkRouterProps extends LinkProps {
  //   to: string;
  //   replace?: boolean;
  // }

  // function LinkRouter(props: LinkRouterProps) {
  //   return <Link {...props} component={RouterLink as any} />;
  // }

  // const breadcrumbNameMap: { [key: string]: string } = {
  //   "/help-center": "Help Center",
  //   [`/help-center/${ArticleId.DeviceBasicCharacteristics}`]:
  //     ARTICLES[ArticleId.DeviceBasicCharacteristics].title,
  //   [`/help-center/${ArticleId.DeviceClassification}`]:
  //     ARTICLES[ArticleId.DeviceClassification].title,
  //   [`/help-center/${ArticleId.QualityManagementSystem}`]:
  //     ARTICLES[ArticleId.QualityManagementSystem].title,
  // };

  return (
    <div className="flex flex-col">
      <Breadcrumps />

      <Typography variant="h1">{article.title}</Typography>
      {loading && (
        <div className="flex flex-1 w-full flex-col mt-4 gap-y-4 overflow-hidden">
          {[...Array(4)].map((_) => (
            <div>
              <Skeleton
                variant="text"
                width={"25%"}
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton
                variant="text"
                width={"85%"}
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton variant="rounded" width={"45%"} height={60} />
              <Skeleton
                variant="text"
                width={"85%"}
                sx={{ fontSize: "1rem" }}
              />
              <Skeleton
                variant="text"
                width={"85%"}
                sx={{ fontSize: "1rem" }}
              />
            </div>
          ))}
        </div>
      )}
      {!loading && (
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
          className="whitespace-pre-wrap max-w-5xl"
        />
      )}
    </div>
  );
});
