import { TEMPLATE_TYPE } from "@models";
import {
  FunctionalGroup,
  TemplateAssistant,
  TemplateFunctionalGroupMap,
} from "../../types";

import { config } from "./doc-config";

export { config };

export const FunctionalGroupNames: Record<FunctionalGroup, string> = {
  [FunctionalGroup.TD]: "Technical",
  [FunctionalGroup.QA]: "Quality Management and Assurance",
  [FunctionalGroup.UNGROUPED]: "Misc.",
  [FunctionalGroup.SW]: "Software",
  [FunctionalGroup.CP]: "Corporate",
  [FunctionalGroup.PD]: "Product",
};

export const templatesWithUpload: TEMPLATE_TYPE[] = Object.values(config)
  .filter((template) => template.userUpload)
  .map((template) => template.id);

/**
 * Check if document config is purposely, or accidentally because its missing fields, hidden.
 */
export const documentIsHidden = (templateConfig: TemplateAssistant) => {
  return (
    !templateConfig.docName ||
    !templateConfig.docType ||
    !templateConfig.functionalGroup ||
    templateConfig.hidden
  );
};

// TODO: Rewrite with groupBy (below) when new node/TS available.
// export const templatesByFunctionalGroup = Object.groupBy(Object.entries(config), ({functionalGroup} => functionalGroup)
export const templatesByFunctionalGroup = Object.values(
  config
).reduce<TemplateFunctionalGroupMap>((result, currentItem) => {
  if (!documentIsHidden(currentItem)) {
    result[currentItem.functionalGroup] =
      result[currentItem.functionalGroup] || [];
    result[currentItem.functionalGroup]!.push(currentItem);
  }
  return result;
}, {});
