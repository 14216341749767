import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import React from "react";

export const TodoCard = ({
  text,
  icon,
  navigateToTodo,
  isActive,
}: {
  closeTodo: () => void;
  text: string;
  icon: React.ReactNode;
  navigateToTodo: () => void;
  isActive: boolean;
}) => {
  return (
    <ListItemButton
      onClick={() => {
        navigateToTodo();
      }}
      selected={isActive}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};
