import { User } from "@models";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useUpdateUserMutation } from "../../hooks";

const UserInfo = ({
  user,
  setZoom,
  setIsOnboardingOpen,
}: {
  user: User;
  setZoom: Dispatch<SetStateAction<boolean>>;
  setIsOnboardingOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
  });

  const patchUserMutation = useUpdateUserMutation();

  const handleUpdateUser = () => {
    console.log(formData);
    patchUserMutation.mutate({ id: user.id, user: formData });
    setIsOnboardingOpen(false);
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h1" id="modal-modal-title">
          Tell us about yourself
        </Typography>
        <DialogContentText id="alert-dialog-description" className="pt-2">
          We need some more information about yourself to correctly generate
          content for you
        </DialogContentText>
      </DialogTitle>
      <DialogContent className="flex flex-col gap-2.5">
        <TextField
          id="firstName"
          label="First Name"
          onChange={(v) =>
            setFormData((prev) => ({ ...prev, firstName: v.target.value }))
          }
          value={formData.firstName}
          fullWidth
        />
        <TextField
          id="lastName"
          label="Last Name"
          onChange={(v) =>
            setFormData((prev) => ({ ...prev, lastName: v.target.value }))
          }
          value={formData.lastName}
          fullWidth
        />
      </DialogContent>
      <DialogContent className="pt-0 flex justify-end">
        <Button
          type="submit"
          variant="contained"
          disabled={!formData.firstName || !formData.lastName}
          onClick={() => {
            handleUpdateUser();
            setZoom(false);
          }}
        >
          Save
        </Button>
      </DialogContent>
    </>
  );
};

export default UserInfo;
