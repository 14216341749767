import { Cloud, Route } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../config";

const DeviceNavbar = ({
  disableTooltip = false,
}: {
  disableTooltip?: boolean;
}) => {
  const { pathname } = useLocation();
  const { deviceId = "" } = useParams();

  return (
    <List>
      <Tooltip
        title="Roadmap"
        placement="right"
        key={"roadmap"}
        disableHoverListener={disableTooltip}
      >
        <ListItemButton
          key="roadmap"
          component={Link}
          to={ROUTES.DEVICE_OVERVIEW.replace(":deviceId", deviceId)}
          state={{ previous: pathname }}
        >
          <ListItemIcon>
            <Route />
          </ListItemIcon>
          <ListItemText primary={"Roadmap"} />
        </ListItemButton>
      </Tooltip>
      <Tooltip
        title="QMS"
        placement="right"
        key={"qms"}
        disableHoverListener={disableTooltip}
      >
        <ListItemButton
          key="qms"
          component={Link}
          to={ROUTES.QMS.replace(":deviceId", deviceId)}
          state={{ previous: pathname }}
        >
          <ListItemIcon>
            <Cloud />
          </ListItemIcon>
          <ListItemText primary={"QMS"} />
        </ListItemButton>
      </Tooltip>
    </List>
  );
};

export default DeviceNavbar;
