import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Snackbar } from "./components/Snackbar/Snackbar";
import { SnackbarProvider } from "./components/Snackbar/useSnackbar";
import { theme } from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./sentry";
import { StoreContext, rootStore } from "./stores";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <StoreContext.Provider value={rootStore}>
      <QueryClientProvider client={queryClient}>
        {/* Add this and import it to enable React Query Devtools */}
        {/* import { ReactQueryDevtools } from "@tanstack/react-query-devtools"; */}
        {/* <ReactQueryDevtools initialIsOpen={false}  /> */}

        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {/* <PostHogProvider
          apiKey={window._env_.PUBLIC_POSTHOG_KEY}
          options={options}
        > */}
            <SnackbarProvider>
              <Snackbar />
              <App />
            </SnackbarProvider>
            {/* </PostHogProvider> */}
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </StoreContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { queryClient };
