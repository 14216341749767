import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config";

type Props = {
  error: Error;
  componentStack: string;
  resetError: () => void;
};

export const Fallback = ({ error, resetError }: Props) => {
  console.error(error);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col self-center">
      <h1>Something went wrong.</h1>
      <p>
        Our team has been notified.
        <br />
        In the meantime, please try refreshing the page or start again from the{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            resetError();
            navigate(ROUTES.DEVICE_MANAGEMENT);
          }}
        >
          Dashboard
        </a>
        .
      </p>
    </div>
  );
};
