import { User } from "@models";
import { Dispatch, SetStateAction, useState } from "react";
import OnboardingModal from "./OnboardingModal";
import UserInfo from "./UserInfo";

const Welcome = ({
  user,
  setIsOnboardingOpen,
}: {
  user: User;
  setIsOnboardingOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [step, setStep] = useState<number>(0);
  const [zoom, setZoom] = useState<boolean>(true);

  const handleOnboardingGetStarted = () => {
    localStorage.setItem("shouldOpenOnboarding", JSON.stringify(false));

    if (!user.firstName || !user.lastName) {
      setZoom(false);
      setTimeout(() => {
        setStep(1);
        setZoom(true);
      }, 200);
      return;
    }

    setIsOnboardingOpen(false);
  };

  return (
    <div
      className={`transition-transform origin-center duration-500 
       ${zoom ? "scale-100" : "scale-0"}`}
    >
      {step === 0 && (
        <OnboardingModal
          handleOnboardingGetStarted={handleOnboardingGetStarted}
        />
      )}
      {step === 1 && (
        <UserInfo
          user={user}
          setZoom={setZoom}
          setIsOnboardingOpen={setIsOnboardingOpen}
        />
      )}
    </div>
  );
};

export default Welcome;
