import { User as Auth0User } from "@auth0/auth0-react";
import { Crisp } from "crisp-sdk-web";
import { autorun, makeAutoObservable } from "mobx";
// import posthog from "posthog-js";
import { RootStoreType } from ".";
import { User } from "./models";

export class UserStore {
  rootStore;

  _user: null | User = null;
  _auth0User: null | Auth0User = null;

  onboardingCompleted = false;
  onboardingRemindLater = false;

  constructor(rootStore: RootStoreType) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
    this.initAutoruns();
  }

  initAutoruns() {
    autorun(() => {
      if (this._user && this._auth0User) {
        Crisp.user.setEmail(this._auth0User.email!);
        // posthog?.identify(this._user.id, {
        //   email: this._auth0User.email,
        // });
        //  posthog?.debug(false);
      }
    });
  }

  setAuth0User(user: Auth0User) {
    this._auth0User = user;
  }
}
