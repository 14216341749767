import { chain, uniq } from "lodash";
import { Entries } from "type-fest";
import { ASSISTANT_CONFIG, ROADMAP_TASKS, ROUTES } from "../config";
import {
  Document,
  DocumentVersion,
  DocumentVersionApprover,
  DocumentVersionApproverStatus,
  TEMPLATE_TYPE,
  User,
} from "../stores/models";
import {
  DocStatus,
  DocumentDataKey,
  EnabledDocStatus,
  TemplateElement,
} from "../types";

export const getDocTemplate = (templateId: string) => {
  if (Object.values(TEMPLATE_TYPE).includes(templateId as TEMPLATE_TYPE)) {
    return ASSISTANT_CONFIG[templateId as TEMPLATE_TYPE];
  } else {
    return undefined;
  }
};
/**
 * Get all template types where the respective documents are not completed but specified as required context by one of the elements in in given template type
 */
const getIncompleteContexts = (
  templateType: TEMPLATE_TYPE,
  documents: Document[]
): TEMPLATE_TYPE[] => {
  const { elements } = ASSISTANT_CONFIG[templateType];

  const configOfRelatedDocuments: TEMPLATE_TYPE[] = uniq(
    elements
      .filter((e): e is TemplateElement => !e.hasOwnProperty("entity"))
      .map((e) => e.context || [])
      .flat()
      .map(
        (dataKey) =>
          (Object.entries(ASSISTANT_CONFIG).find(
            ([k, v]) =>
              k !== templateType &&
              v.elements.map((e) => e.id).includes(dataKey)
          )?.[0] as TEMPLATE_TYPE) || []
      )
      .flat()
  );

  // Check if every document that is mentioned inside the context config in the TemplateAssistantConfig is present in the documents array and marked as completed
  return configOfRelatedDocuments.filter((documentId) => {
    return (
      !documents.find((d) => d.name === documentId) ||
      documents.find((d) => d.name === documentId)?.versions[0]
        ?.readyForApproval === false
    );
  });
};

const getIncompleteDependencies = (
  templateType: TEMPLATE_TYPE,
  documents: Document[]
): TEMPLATE_TYPE[] => {
  const incompleteDependencies: TEMPLATE_TYPE[] = [];

  // Check if every document that is mentioned inside the dependencies in the TemplateAssistantConfig is present in the documents array and marked as completed
  ASSISTANT_CONFIG[templateType].dependencies?.forEach((dependency) => {
    const templateRelatedToDependency = Object.entries(ASSISTANT_CONFIG).find(
      ([_, value]) =>
        value.elements.some((element) => element.id === dependency)
    )?.[0];

    if (!templateRelatedToDependency) {
      throw new Error(
        `Could not find template related to dependency ${dependency}`
      );
    }

    const isDependencyDocumentComplete =
      documents.find((d) => d.name === templateRelatedToDependency)?.versions[0]
        ?.readyForApproval === true;

    if (!isDependencyDocumentComplete) {
      incompleteDependencies.push(templateRelatedToDependency as TEMPLATE_TYPE);
    }
  });

  return incompleteDependencies;
};

const allStepsCompleted = (
  document: Document,
  documentVersion: DocumentVersion
) => {
  const values = Object.entries(getDocumentAnswerMap(documentVersion));

  const steps = getDocumentStepsFromConfig(document.name as TEMPLATE_TYPE);

  return steps.every((step) => {
    const [_, answer] = values.find(([k]) => k === step.id) || [];

    // Steps that are not required can always be considered completed
    if (!step.required) return true;

    // If the trimmed answer is empty and the step is required and the default value is not set than the step is not completed
    const answerIsDefinedAndIsNotEmpty = !!answer && answer.trim() !== "";

    return (
      (answerIsDefinedAndIsNotEmpty || step.element.options.default) &&
      step.required
    );
  });
};

export const isDocumentHidden = (
  templateId: TEMPLATE_TYPE,
  documents: Document[]
) => {
  if (ASSISTANT_CONFIG[templateId].hidden === true) {
    return true;
  }
  // get the visibility of the document from the tasks config
  const task = ROADMAP_TASKS.find((task) => task.id === templateId);

  if (!task) {
    throw new Error("Task not found in roadmap tasks: " + templateId);
  }

  if (task.visibilityCondition) {
    const answer = documents
      .map((doc) => {
        return doc.versions[0]?.answers.find(
          (answer) => answer.element === task.visibilityCondition?.dataKey
        );
      })
      .find((answer) => answer !== undefined);

    if (!answer || !answer.answer) {
      return true;
    }

    return !task.visibilityCondition.isVisible(answer.answer);
  }

  return false;
};

/**
 * Checks if a document is ready for a user to fill out.
 */
export const isDocumentUnlocked = (
  templateType: TEMPLATE_TYPE,
  documents: Document[]
): EnabledDocStatus => {
  const incompleteContexts = getIncompleteContexts(templateType, documents);
  const incompleteDependencies = getIncompleteDependencies(
    templateType,
    documents
  );

  const missingDocuments =
    ASSISTANT_CONFIG[templateType].docReferences?.filter(
      (ref) => !documents.find((d) => d.name === ref)
    ) || [];

  // TODO check if list of inputs in prePromptTransformerConfig and element.transformerConfig are present in the documents array and marked as completed
  // ASSISTANT_CONFIG[templateType].elements.forEach((element) => {
  // })
  const incompleteDocuments = uniq([
    ...incompleteContexts,
    ...incompleteDependencies,
    ...missingDocuments,
  ]);

  const isDocumentEnabled = !incompleteDocuments.length;
  return {
    enabled: isDocumentEnabled,
    ...(!isDocumentEnabled && { incompleteDocuments }),
  };
};

/**
 * Checks if a static or non-static document is finished by the user
 */
export const isDocumentCompleted = (
  document: Document,
  documentVersion?: DocumentVersion
) => {
  if (documentVersion === undefined) return false;

  const docTemplate = getDocTemplate(document.name);
  //TODO: does this make sense to ask if readyForApproval?
  if (docTemplate !== undefined) {
    if (isStaticallyGeneratedDocument(docTemplate.id)) {
      return true;
    }
  }
  if (!documentVersion.readyForApproval) {
    return false;
  }

  return allStepsCompleted(document, documentVersion);
};

/**
 * Takes the answers from the document version and returns an object with the answers as values and the corresponding data keys as keys
 */
export const getDocumentAnswerMap = (documentVersion: DocumentVersion) => {
  const mappedAnswers: Partial<Record<DocumentDataKey, string>> = {};

  if (Array.isArray(documentVersion.answers)) {
    documentVersion.answers.forEach((answer) => {
      mappedAnswers[answer.element] = answer.answer;
    });
  }

  // Merge the answers from the document version with the answers from the state
  return mappedAnswers;
};

export const getDocumentStepsFromConfig = (
  type: TEMPLATE_TYPE
): TemplateElement[] => {
  return ASSISTANT_CONFIG[type].elements.filter((e): e is TemplateElement =>
    e.hasOwnProperty("element")
  );
};

export const isStaticallyGeneratedDocument = (
  templateId: TEMPLATE_TYPE
): boolean =>
  !hasDocInputs(templateId) && !ASSISTANT_CONFIG[templateId].userUpload;

export const isStaticAndHasNoDependencies = (templateId: TEMPLATE_TYPE) =>
  isStaticallyGeneratedDocument(templateId) &&
  !ASSISTANT_CONFIG[templateId].dependencies?.length;

export const hasDocInputs = (templateId: TEMPLATE_TYPE): boolean => {
  const templateConfig = ASSISTANT_CONFIG[templateId];
  return (
    templateConfig.elements.filter((e) => !e.hasOwnProperty("entity"))
      .length !== 0
  );
};

export const getAfterDocCreationRedirect = (
  templateId: TEMPLATE_TYPE,
  deviceId: string
) => {
  const docIsStatic = isStaticallyGeneratedDocument(templateId);

  if (docIsStatic) {
    return ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
      ":templateId",
      templateId
    );
  } else {
    return ROUTES.TEMPLATE_ASSISTANT.replace(":deviceId", deviceId).replace(
      ":templateId",
      templateId
    );
  }
};

export const getElementConfigByDataKey = (
  dataKey: DocumentDataKey
): TemplateElement => {
  const docConfigs = Object.entries(ASSISTANT_CONFIG) as Entries<
    typeof ASSISTANT_CONFIG
  >;
  // Get the document config that contains the element with the dataKey
  const docConfigOfElement = docConfigs.find(([_, config]) =>
    config.elements.some((element) => element.id === dataKey)
  );
  if (!docConfigOfElement) {
    throw new Error(`Could not find document config for dataKey ${dataKey}`);
  }
  // Get the element config that has the dataKey
  const elementConfig = docConfigOfElement[1].elements
    // Filter out the elements that are not TemplateElement
    .filter((e): e is TemplateElement => !e.hasOwnProperty("entity"))
    .find((element) => element.id === dataKey);
  if (!elementConfig) {
    throw new Error(`Could not find element config for dataKey ${dataKey}`);
  }
  return elementConfig;
};

export const loadDocumentReferences = (
  templateId: TEMPLATE_TYPE,
  documents: Document[]
): Record<string, string> => {
  const referenceObject: Record<string, string> = {};

  ASSISTANT_CONFIG[templateId].docReferences?.forEach((docReference) => {
    const document = documents.find((d) => d.name === docReference);
    if (!document || !document.versions[0]) {
      throw new Error("Document reference not found or has no versions");
    }
    const documentIdentifier = getDocumentIdentifier(
      docReference,
      document,
      document.versions[0],
      document.versions
    );
    referenceObject[`did-${docReference}`] = documentIdentifier;
  });

  return referenceObject;
};

export const getDocumentIdentifier = (
  templateType: TEMPLATE_TYPE,
  document: Document,
  documentVersion: DocumentVersion,
  documentVersions: DocumentVersion[]
) => {
  const templateConfig = ASSISTANT_CONFIG[templateType];
  const { docName, docType, functionalGroup } = templateConfig;
  const documentNumber = document.id;
  const revision = getDocVersionRevisionNumber(
    documentVersion,
    documentVersions
  );
  return `${functionalGroup}-${docType}-${documentNumber}-R${revision}-${docName}`;
};

export const isDocRevisionLive = (
  docRevision: DocumentVersion,
  docVersions: DocumentVersion[]
) => {
  if (typeof docRevision.revision !== "number") return false;

  const allRevisions = chain(docVersions)
    .filter((v) => typeof v.revision === "number")
    .orderBy("revision", "desc")
    .value();

  if (allRevisions.length === 0) return false;

  const isLatestRevision = allRevisions[0]?.id === docRevision.id;
  return isLatestRevision;
};

export const isDocVersionDraft = (
  docVersion: DocumentVersion,
  allDocVersions: DocumentVersion[]
) => {
  if (docVersion.revision !== null) return false;

  const latestRevision = chain(allDocVersions)
    .filter((v) => typeof v.revision === "number")
    .orderBy("revision", "desc")
    .value()[0];

  if (latestRevision && docVersion.createdAt < latestRevision.createdAt)
    return false;

  const allVersionsThatAreNotRevisions = chain(allDocVersions)
    .filter((v) => v.revision === null)
    .orderBy("createdAt", "desc")
    .value();

  if (allVersionsThatAreNotRevisions.length === 0) return false;

  const isLatestVersion =
    allVersionsThatAreNotRevisions[0]?.id === docVersion.id;
  return isLatestVersion;
};

export const isDocRevisionArchived = (
  docVersion: DocumentVersion,
  docVersions: DocumentVersion[]
) => {
  if (typeof docVersion.revision !== "number") return false;

  // If it is not the latest revision then it is archived
  const allRevisions = chain(docVersions)
    .filter((v) => typeof v.revision === "number")
    .orderBy("revision", "desc")
    .value();

  if (allRevisions.length <= 1) return false;

  return allRevisions[0]?.id !== docVersion.id;
};

export const isDocEditable = (doc: Document) => {
  const docTemplate = getDocTemplate(doc.name);

  if (docTemplate !== undefined) {
    const config = ASSISTANT_CONFIG[docTemplate.id];
    if (["TMP", "EXT"].includes(config.docType)) {
      return false;
    } else {
      return true;
    }
  }
  // Default: if the document is not of type TEMPLATE_TYPE then it is not editable
  return false;
};

export const canPrecreateDocumentVersion = (templateId: TEMPLATE_TYPE) =>
  !ASSISTANT_CONFIG[templateId].userUpload;

export const getDocVersionStatus = (
  docVersion: DocumentVersion,
  allDocVersionsOfSameDoc: DocumentVersion[]
): DocStatus | null => {
  if (isDocVersionDraft(docVersion, allDocVersionsOfSameDoc)) {
    return "DRAFT";
  }
  if (isDocRevisionLive(docVersion, allDocVersionsOfSameDoc)) {
    return "LIVE";
  }
  if (isDocRevisionArchived(docVersion, allDocVersionsOfSameDoc)) {
    return "ARCHIVED";
  }
  return null;
};

export const getDocVersionRevisionNumber = (
  docVersion: DocumentVersion,
  allDocVersionsOfSameDoc: DocumentVersion[]
): number => {
  if (typeof docVersion.revision === "number") {
    return docVersion.revision + 1;
  } else {
    // Check if there are any other revisions and if yes then return the highest revision number + 1
    const revisions = allDocVersionsOfSameDoc.filter(
      (v) => typeof v.revision === "number"
    );

    const isFirstRevision = revisions.length === 0;
    if (isFirstRevision) return 1;
    const highestRevision = Math.max(
      ...revisions.map((v) => v.revision as number)
    );

    return highestRevision + 2;
  }
};
export const isDocumentApproved = ({
  approvers,
  requiredNumberOfApprovers = 2,
}: {
  approvers: DocumentVersion["documentVersionApprover"];
  requiredNumberOfApprovers?: number;
}) =>
  approvers &&
  approvers.length === requiredNumberOfApprovers &&
  approvers.every(
    (approver: DocumentVersionApprover) =>
      approver.approvalStatus === DocumentVersionApproverStatus.APPROVED
  );

export const hasRevisionOneOreMoreApprovals = (docVersion: DocumentVersion) => {
  return docVersion.documentVersionApprover.some(
    (a) => a.approvalStatus === DocumentVersionApproverStatus.APPROVED
  );
};

export const isDocVersionAuthor = (version: DocumentVersion, user: User) =>
  version.createdBy === user.id;
