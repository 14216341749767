import { Breadcrumbs, Link, LinkProps, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ARTICLES, ArticleId } from "../../config";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export const Breadcrumps = observer(() => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
  }

  const breadcrumbNameMap: { [key: string]: string } = {
    "/help-center": "Help Center",
    [`/help-center/${ArticleId.DeviceBasicCharacteristics}`]:
      ARTICLES[ArticleId.DeviceBasicCharacteristics].title,
    [`/help-center/${ArticleId.DeviceClassification}`]:
      ARTICLES[ArticleId.DeviceClassification].title,
    [`/help-center/${ArticleId.QualityManagementSystem}`]:
      ARTICLES[ArticleId.QualityManagementSystem].title,
    [`/help-center/${ArticleId.RiskManagementSystem}`]:
      ARTICLES[ArticleId.RiskManagementSystem].title,
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" className="mb-4">
      {pathnames.map((_, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        // Disable help center link for now
        return last || to === "/help-center" ? (
          <Typography color="text.primary" key={to} className="text-xs">
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter
            underline="hover"
            color="inherit"
            to={to}
            key={to}
            className="text-xs"
          >
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
});
