import { EditOutlined, LockOutlined, TaskOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../config";
import { config as TemplateAssistantConfig } from "../../../config/assistant";
import { TEMPLATE_TYPE } from "../../../stores/models";
import { DocProgress, TemplateAssistant } from "../../../types";
import { TemplateTreeItem } from "./TemplateTreeItem";
import { ToolTipTemplateTreeItem } from "./ToolTipTemplateTreeItem";

const getDocumentTitles = (templateIds: TEMPLATE_TYPE[]) => {
  return templateIds.map((templateId) => {
    return TemplateAssistantConfig[templateId].docName;
  });
};

const DocExplorerLeaf = ({
  template,
  deviceId,
  docProgress,
}: {
  template: TemplateAssistant;
  deviceId: string;
  docProgress?: DocProgress;
}) => {
  // Default: Locked if unsure what to do with item
  if (!docProgress) {
    return (
      <ToolTipTemplateTreeItem
        template={template}
        title={`Document Locked.`}
        disabled
        slots={{ icon: LockOutlined }}
      />
    );
  }

  if (docProgress.enabled === false) {
    return (
      <ToolTipTemplateTreeItem
        template={template}
        title={`Document Locked. ${docProgress?.incompleteDocuments?.length !== 0 ? `Please complete: '${getDocumentTitles(docProgress.incompleteDocuments || []).join("', '")}'.` : ""}`}
        slots={{ icon: LockOutlined }}
        disabled
      />
    );
  }

  if (docProgress.noInstance === true) {
    return (
      <Link
        to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
          ":templateId",
          template.id
        )}
        className={`text-body no-underline`}
      >
        <TemplateTreeItem
          template={template}
          slots={{ icon: TaskOutlined }}
          blueIcon
        />
      </Link>
    );
  }

  if (docProgress.complete === false) {
    return (
      <Link
        to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
          ":templateId",
          template.id
        )}
        className={`text-body no-underline`}
      >
        <ToolTipTemplateTreeItem
          template={template}
          title="Document Incomplete. Click to continue."
          slots={{ icon: EditOutlined }}
        />
      </Link>
    );
  }

  // Render completed documents
  return (
    <Link
      to={ROUTES.QMS_OPEN_DOC.replace(":deviceId", deviceId).replace(
        ":templateId",
        template.id
      )}
      className={`text-body no-underline`}
    >
      <TemplateTreeItem
        template={template}
        slots={{ icon: TaskOutlined }}
        blueIcon
      />
    </Link>
  );
};

export default DocExplorerLeaf;
