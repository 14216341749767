import { BaseUser, DocumentVersionApprover } from "@models";
import { Alert, LinearProgress } from "@mui/material";

type Props = {
  approvers: DocumentVersionApprover[];
  docVersionAuthor: BaseUser;
  requiredNumberOfApprovers: number;
};
export const NotInvolvedContent = ({
  approvers,
  docVersionAuthor,
  requiredNumberOfApprovers,
}: Props) => {
  return (
    <div className="h-80 pt-1">
      <Alert
        severity="info"
        sx={{
          borderRadius: "4px 4px 0px 0px",
        }}
      >
        {approvers.length === requiredNumberOfApprovers
          ? `Waiting for ${approvers[0].user.firstName} ${approvers[0].user.lastName} and ${approvers[1].user.firstName} ${approvers[1].user.lastName} to approve.`
          : `Waiting for ${docVersionAuthor.firstName} ${docVersionAuthor.lastName} to assign approvers.`}
      </Alert>
      <div>
        <LinearProgress
          sx={{
            backgroundColor: "#e5f6fD",
            borderRadius: "0px 0px 4px 4px",
            "& .MuiLinearProgress-bar1Indeterminate": {
              backgroundColor: "unset",
            },
            "& .MuiLinearProgress-bar2Indeterminate": {
              backgroundColor: "#0288d1",
            },
          }}
        />
      </div>
    </div>
  );
};
