import { useCheckoutMutation, useGetUser } from "@hooks";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Loading } from ".";

import {
  BillingCycleSelector,
  DeviceCountSelector,
  PlanCard,
  plans,
  useSnackbar,
} from "@components";
import { BillingInterval, Plan } from "@models";
import { getSubscription } from "@utils";
import { Crisp } from "crisp-sdk-web";

export const Subscription = () => {
  const { data: user } = useGetUser();
  const subscription = user ? getSubscription(user) : null;

  const { showSnackbar } = useSnackbar();
  const { mutateAsync: checkout } = useCheckoutMutation();
  const [billingCycle, setBillingCycle] = useState<BillingInterval>(
    BillingInterval.YEARLY
  );
  const [deviceCount, setDeviceCount] = useState<number>(1);
  const [loadingPlan, setLoadingPlan] = useState<Plan | null>(null);

  useEffect(() => {
    if (subscription) {
      setBillingCycle(subscription.billingInterval);
      setDeviceCount(subscription.quantity);
    }
  }, [user]);

  const getPrice = (plan: { monthlyPrice: number; yearlyPrice: number }) => {
    if (billingCycle === BillingInterval.YEARLY) {
      return plan.yearlyPrice.toFixed(0);
    }
    return plan.monthlyPrice.toFixed(0);
  };

  if (!user || !subscription) {
    return <Loading />;
  }

  const hasSubscription = subscription.product !== Plan.FREE;

  const handleChoosePlan = async (selectedPlan: Plan, amount: number) => {
    setLoadingPlan(selectedPlan);
    try {
      // If the plan is free or the user has a subscription, we need to create a billing session
      if (hasSubscription || selectedPlan === Plan.FREE) {
        Crisp.chat.show();
        Crisp.chat.open();
      } else {
        const response = await checkout({
          plan: selectedPlan,
          amount,
          cycle: billingCycle,
        });
        if (response) {
          window.location.href = response;
        } else {
          console.error("Checkout URL not provided in the response");
          showSnackbar("Create a billing session failed. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    } finally {
      setLoadingPlan(null);
    }
  };

  return (
    <div className="flex flex-col self-center w-full">
      <Typography variant="h1" className="mt-2">
        Plans
      </Typography>
      <div className="flex justify-between items-center mt-4 px-3 py-2 bg-gray-100 rounded-lg">
        <BillingCycleSelector
          billingCycle={billingCycle}
          setBillingCycle={setBillingCycle}
        />
        <DeviceCountSelector
          deviceCount={deviceCount}
          setDeviceCount={setDeviceCount}
        />
      </div>

      <div className="flex flex-1 w-full gap-10 mt-8 justify-center">
        {plans.map((plan) => (
          <PlanCard
            key={plan.name}
            plan={plan}
            deviceCount={deviceCount}
            loadingPlan={loadingPlan}
            getPrice={getPrice}
            handleChoosePlan={handleChoosePlan}
            selectedBillingCycle={billingCycle}
            user={user}
          />
        ))}
      </div>
    </div>
  );
};
